import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import FormulariosTableHeader from "./FormulariosTableHeader";

function FormulariosTable({
  data = [],
  headers = [],
  searchValue = "",
  onSearch,
  onRowClick = () => { },
  extraClass = "",
  loading = false,
  extraData = {},
  renderActionButton,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeRowNumberMenu, setActiveRowNumberMenu] = useState(null);

  const activeRowNumberMenuHandler = (rowNumber) => {
    activeRowNumberMenu === rowNumber
      ? setActiveRowNumberMenu(null)
      : setActiveRowNumberMenu(rowNumber);
  };

  const onTableClick = (e) => {
    console.info("onTableClick");
    e.stopPropagation();
    activeRowNumberMenuHandler(null);
  };

  const FormularioRowItem = ({ item, className = "" }) => {
    const getRelacionColor = (relacion) => {
      let relacionColor = "bg-slate-200 text-black";
      const colores = {
        "Jefe directo": "bg-red-500",
        Par: "bg-orange-400",
        Subalterno: "bg-sky-500",
        Autoevaluación: "bg-black",
      };
      if (colores[relacion]) return colores[relacion];
      return relacionColor;
    };
    //
    return (
      <div
        className={"table-row hover:bg-sky-100 " + className}
        onClick={onRowClick(item)}
      >
        {/* FIXED COL: Colaborador */}
        <div className="table-cell align-middle p-4">
          <div className="flex items-center gap-2">
            <div className="flex items-center w-56x relativex">
              <ProfileInfoCard
                key={"col-" + item.id}
                image={item.evaluado.foto_empleado}
                name={
                  item.id
                    ? `${item.evaluado.nombre} ${item.evaluado.apellido}`
                    : "Sin especificar"
                }
              >
                <div className="text-sm">
                  {item.evaluado?.cargo?.nombre || "Sin asignar"}
                </div>
              </ProfileInfoCard>
              {/* <div className="w-6 flex justify-center">
                <button onClick={hideDropDownMenuHandler(item.id)}>
                  <ThreeDotsVertical size={18} />
                </button>
              </div> */}
            </div>
          </div>
        </div>
        {/* ALL THE REST */}
        <div className="table-cell borderx p-4 align-middle text-center min-w-[150px]x">
          <div className="text-left">
            <span
              className={`${getRelacionColor(
                item.relacion
              )} p-1 rounded-md text-white text-sm`}
            >
              {item.relacion || "Sin asignar"}
            </span>
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center">
          <div className="w-52x p-4 text-left">
            {item.configuracion?.nombre || "Asignar"}
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center">
          {!!item.mostrar_puntuacion && (
            <div className="text-left">
              {item.puntaje_obtenido.value === "-" ? (
                <>{item.puntaje_obtenido.label}</>
              ) : (
                <>{item.puntaje_obtenido.value}% - {item.puntaje_obtenido.label}</>
              )}
            </div>
          )}
          {!item.mostrar_puntuacion && <div className="text-left">Pendiente</div>}
        </div>
        <div className="table-cell align-middle text-center">
          {!!renderActionButton && renderActionButton(item)}
          {!renderActionButton && <>Sin acción</>}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col gap-8 w-full ${extraClass}`}
      onClick={onTableClick}
    >
      {/* SEARCH BAR AND ACTIONS */}
      <div>
        <div className="flex items-center gap-4">
          <BuscarInput
            onChange={onSearch}
            value={searchValue}
            placeholder={"Buscar por nombre o cargo del colaborador"}
          ></BuscarInput>
          {/* <div className="flex items-center gap-4">
            <ReactSelect
              className="w-52x h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: "(" + [1, 2, 3].length + ") seleccionados",
              }}
              options={[
                { value: "add-evaluador", label: "Añadir evaluador" },
                {
                  value: "add-evaluador-defecto",
                  label: "Añadir evaluador por defecto",
                },
                { value: "add-calibrador", label: "Añadir calibrador" },
                { value: "add-formulario", label: "Añadir formulario" },
                { value: "enviar-evaluaciones", label: "Enviar evaluaciones" },
                {
                  value: "eliminar-colaboradores",
                  label: "Eliminar colaboradores",
                },
                {
                  value: "eliminar-evaluadores",
                  label: "Eliminar evaluadores",
                },
                {
                  value: "eliminar-colaboradres",
                  label: "Eliminar calibradores",
                },
                { value: "reiniciar", label: "Reiniciar" },
                { value: "eliminar", label: "Eliminar" },
                { value: "cancel", label: "Cancelar" },
              ]}
              onChange={onAction}
            ></ReactSelect>
            <button
              onClick={actionButtonHandler}
              className="h-10 bg-sky-500 text-white text-sm px-4 rounded "
            >
              {`Agregar`}
            </button>
          </div> */}
        </div>
      </div>
      {/* TABLE */}
      <div className="table-container w-full relativex custom-scrollbarx overflow-y-scrollx overflow-auto max-h-[780px]x">
        <div
          className={`${data.length < 1 ? "table" : "table"
            } h-1/2 overflow-x-scrollx overflow-autox w-fullx borderx rounded-md`}
        >
          <div className="table-header-group font-bold text-sm">
            {/* HEADERS */}
            {headers.length > 0 && (
              <div className="table-row">
                <div className="table-cell w-80x bg-white z-10 sticky top-0 left-0 border-b border-red-400x py-3 px-4">
                  <span className="ml-2">{headers[0].text}</span>
                </div>
                {headers.map(
                  (header, idx) =>
                    idx !== 0 && (
                      <div className="table-cell border-b-2 w-40x bg-white z-1 sticky top-0 py-3 px-4">
                        <FormulariosTableHeader
                          text={header.text}
                          onOrder={header.onOrder}
                          onFilter={header.onFilter}
                        ></FormulariosTableHeader>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          {/* loading */}
          {!!loading && (
            <div className="table-row-group animate-pulse">
              {[1, 2, 3].map((item, idx) => (
                <div className="table-row">
                  {headers.map((h, i) => (
                    <div className="table-cell p-4">
                      <div className="h-10 w-80x">
                        <div className="bg-slate-100 rounded-md h-2 w-1/2x mt-2"></div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          {!loading && data.length > 0 && (
            <div className="table-row-group">
              {data.map((item, idx) => (
                <FormularioRowItem item={item}></FormularioRowItem>
              ))}
              {/* workaround provisorio para que no se pierda height de las rows */}
              {data.length < 4 &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((r, i) => (
                  <div className="table-row opacity-0">{r}</div>
                ))}
            </div>
          )}
          {!loading && data.length < 1 && (
            <div className="table-row">
              <div className="table-cell"></div>
              <div className="table-cell"></div>
              <div className="table-cell py-4">
                No existen registros para mostrar.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default FormulariosTable;
