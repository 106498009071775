import React from 'react';

const TabButton = ({ title, isActive, action }) => {
  const buttonStyle = {
    fontSize: '0.75rem', // text-xs
    borderRadius: '0.25rem', // rounded
    padding: '0.25rem 0.5rem', // px-2 py-1
    backgroundColor: '#f4f4f5', // bg-zinc-100
    border: `1px solid ${isActive ? '#0ea5e9' : '#f4f4f5'}`, // border-sky-500 or border-zinc-100
  };

  return (
    <button style={buttonStyle} onClick={action}>
      {title}
    </button>
  );
};

export default TabButton;
