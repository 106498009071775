import React from "react";
import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
} from "victory";

//
function BarChart({
  data = [],
  height,
  width,
  domain = { y: [0, 100] },
  domainPadding = { x: 100, y: [0, 0] },
  padding, // = { top: 50, bottom: 50, right: 200, left: 200 },
  usingXLabels = false,
}) {
  // const { width: widthResonsive, height: heightResonsive } = useSize(
  //   ref.current
  // );
  const yAxxis = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const labelStyles = {
    // fontSize: 10, // Tamaño de fuente del texto de la etiqueta
    background: "steelblue", // Color de fondo de la etiqueta
    padding: 6, // Espaciado interno de la etiqueta
    borderRadius: 3, // Radio de borde de la etiqueta
    display: "flex", // Mostrar como un elemento flex
    alignItems: "center", // Alinear elementos verticalmente en el centro
    justifyContent: "center", // Alinear elementos horizontalmente en el centro
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)", // Sombra del contenedor de la etiqueta
  };
  //
  const getNombre = (cantidadBarras, nombre) => {
    let result = "";
    switch (cantidadBarras) {
      case 1:
        result = nombre;
        break;
      case 2:
        result = nombre.length > 75 ? nombre.substring(0, 75) + "..." : nombre;
        break;
      case 3:
        result = nombre.length > 33 ? nombre.substring(0, 33) + "..." : nombre;
        break;
      case 4:
        result = nombre.length > 20 ? nombre.substring(0, 20) + "..." : nombre;
        break;
      case 5:
        result = nombre.length > 14 ? nombre.substring(0, 14) + "..." : nombre;
        break;
      case 6:
        result = nombre.length > 10 ? nombre.substring(0, 10) + "..." : nombre;
        break;
      case 7:
        result = nombre.length > 7 ? nombre.substring(0, 7) + "..." : nombre;
        break;
      case 8:
        result = nombre.length > 6 ? nombre.substring(0, 6) + "..." : nombre;
        break;
      default:
        result = nombre.length > 4 ? nombre.substring(0, 4) + "..." : nombre;
        break;
    }
    return result;
  };
  //
  return (
    <div className="w-full h-full">
      <VictoryChart
        //
        {...(!!height ? { height: height } : {})}
        {...(!!width ? { width: width } : {})}
        {...(!!padding ? { padding: padding } : {})}
        domain={{ ...domain }}
        domainPadding={domainPadding}
      >
        <VictoryGroup>
          {/* Inferior */}
          <VictoryBar
            cornerRadius={{ top: 4 }}
            dataComponent={<Bar />}
            labelComponent={
              <VictoryLabel
                dy={({ datum }) => (datum.y < 20 ? -10 : 20)}
                style={{ fontSize: 12 }}
                // backgroundStyle={{ fill: "white" }}
                backgroundPadding={5}
              />
            }
            style={{
              parent: { pointerEvents: "none" },
              data: {
                width: 40,
                fill: ({ datum }) =>
                  !!datum.fill ? datum.fill : "rgb(14 165 233)",
                opacity: (d) => 1,
              },
            }}
            data={data}
            labels={({ datum }) => `${datum.y}%`}
          />
          {/* Superior */}
          <VictoryBar
            cornerRadius={{ top: 4 }}
            dataComponent={<Bar />}
            labelComponent={
              <VictoryLabel
                style={labelStyles}
                backgroundStyle={{ fill: "white" }}
                backgroundPadding={0}
              />
            }
            style={{
              parent: { pointerEvents: "none" },
              data: { fill: "transparent" },
            }}
            data={data}
            labels={({ datum }) => ``}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: "labels",
                        mutation: (props) => {
                          const { datum } = props;
                          return {
                            text: `${datum.name}`,
                            backgroundPadding: 10,
                          };
                        },
                      },
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: "labels",
                        mutation: () => {
                          return null;
                        },
                      },
                    ];
                  },
                },
              },
            ]}
          />
        </VictoryGroup>
        <VictoryAxis
          dependentAxis
          tickValues={yAxxis.map((d, i) => d * 10)}
          style={{
            tickLabels: { angle: 20, fontSize: 12 },
          }}
        />
        {!usingXLabels && (
          <VictoryAxis
            tickValues={data.map((d, i) => d.x)}
            style={{
              tickLabels: { angle: 20, fontSize: 12 },
            }}
            // label={2}
            axisValue={1}
          />
        )}
        {!!usingXLabels && (
          <VictoryAxis
            tickValues={data.map(
              (d, idx) => `${idx + 1}-${getNombre(data.length, d.name)}`
            )}
            style={{
              tickLabels: { fontSize: 12 },
            }}
            // label={2}
            axisValue={1}
          />
        )}
      </VictoryChart>
    </div>
  );
}

export default BarChart;
