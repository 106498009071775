import React, { useEffect, useState } from "react";
import userDefault from "src/modules/common/components/images/user_default.png";
import { DESCRIPCIONES_NINEBOX } from "../helpers/constants";
import Modal from "src/modules/common/components/Modal";
import { InfoCircleFill, XLg } from "react-bootstrap-icons";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";

const getBoxColor = (index) => {
  if (index === 1 || index === 2 || index === 5) {
    return { bg: "rgb(232, 247, 229)", border: "rgb(30, 176, 0)" };
  } else if (index === 0 || index === 4 || index === 8) {
    return { bg: "rgb(255, 247, 230)", border: "rgb(240, 201, 120)" };
  } else if (index === 3 || index === 6 || index === 7) {
    return { bg: "rgb(252, 235, 235)", border: "rgb(246, 146, 105)" };
  }
  return { bg: "transparent", border: "transparent" };
};

const NineBox = ({
  nineboxData,
  porcentajesFormulario = null,
  configuracionFormulario = null,
  mostrarDescripcionesBoxes = false,
  extraClassName = ""
}) => {
  // Reorganizar los cuadrantes en el orden deseado
  /*
  [4] [7] [9]
  [2] [5] [8]
  [1] [3] [6]
  */
  const [reorderedData, setReorderedData] = useState(() => {
    if (nineboxData.boxes?.length === 9) {
      return [
        nineboxData.boxes[5],
        nineboxData.boxes[2],
        nineboxData.boxes[0],
        nineboxData.boxes[7],
        nineboxData.boxes[4],
        nineboxData.boxes[1],
        nineboxData.boxes[8],
        nineboxData.boxes[6],
        nineboxData.boxes[3],
      ];
    }
    return [];
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState({ nombre: "", colaboradores: [] });

  const getNivelPorcentaje = (porcentaje, rangos) => {
    const rangoEncontrado = rangos.find(rango =>
      porcentaje >= rango.puntaje_desde && porcentaje <= rango.puntaje_hasta
    );
    return rangoEncontrado ? rangoEncontrado.nivel : "Desconocido";
  };

  useEffect(() => {
    if (porcentajesFormulario !== null) {
      // Se obtienen los pesos para saber qué opciones de desempeño se marcó
      const { peso_kpis = 0, peso_objetivos_cargo = 0 } = configuracionFormulario;

      // Los porcentajes obtenidos en cada opcion de la configuración del formulario
      const { potenciales = 0, kpis = 0, objetivos_cargo = 0 } = porcentajesFormulario;

      const nivelPotencial = getNivelPorcentaje(potenciales, nineboxData.rangos);

      let cantidadConfiguracionDesempeno = 0;
      let porcentajeDesempeno = 0;
      if (peso_kpis > 0) {
        porcentajeDesempeno += kpis;
        cantidadConfiguracionDesempeno += 1;
      }
      if (peso_objetivos_cargo > 0) {
        porcentajeDesempeno += objetivos_cargo;
        cantidadConfiguracionDesempeno += 1;
      }
      if (cantidadConfiguracionDesempeno > 0) {
        porcentajeDesempeno = porcentajeDesempeno / cantidadConfiguracionDesempeno;
      }
      const nivelDesempeno = getNivelPorcentaje(porcentajeDesempeno || 0, nineboxData.rangos);

      console.log("Cuadrante actual", `${nivelPotencial} (${potenciales}) - ${nivelDesempeno} (${porcentajeDesempeno})`);
      const updatedData = reorderedData.map(box => ({
        ...box,
        cuadranteActual: box.nivel_potencial === nivelPotencial && box.nivel_desempeno === nivelDesempeno
      }));
      setReorderedData(updatedData);
    }
  }, [porcentajesFormulario]);

  const onSelectBox = (box) => {
    setSelectedBox(box);
    setShowModal(true);
  }

  return (
    <>
      <div className="flex items-center space-x-4 pl-8 border-b py-4">
        <div className="flex items-center space-x-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: "#ADD8E6" }} // Color azul claro
          ></span>
          <span className="text-sm font-bold">Vertical: Desempeño</span>
        </div>
        <div className="flex items-center space-x-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: "#90EE90" }} // Color verde claro
          ></span>
          <span className="text-sm font-bold">Horizontal: Potencial</span>
        </div>
      </div>
      <div className="relative">
        {/* Ejes y flechas */}
        <div className="absolute bottom-4 left-0 w-full h-full pointer-events-none">
          {/* Flecha vertical */}
          <div className="absolute top-0 h-full flex flex-col items-center justify-between">
            <h1 className="text-xs">Alto</h1>
            <h1 className="text-xs">Medio</h1>
            <h1 className="text-xs">Bajo</h1>
          </div>
          <div className="absolute left-10 top-0 h-full flex flex-col items-center">
            <div className="flex-grow border-l-4 border-[#ADD8E6]"></div>
          </div>
        </div>

        {/* Caja del NineBox */}
        <div className={`grid grid-cols-3 gap-4 pl-14 ${extraClassName}`}>
          {reorderedData.map((box, index) => (
            <div
              key={box.id}
              className="flex flex-col justify-center items-center rounded-lg p-4 text-center h-32 border-l-4"
              style={{
                backgroundColor: getBoxColor(index).bg,
                borderColor: getBoxColor(index).border,
              }}
            >
              <div className="flex flex-row justify-between items-center gap-2">
                <h3 className="text-lg font-bold">{box.nombre}</h3>
                {/* En reporte individual, solo se muestra el tooltip con la descripción en el box en el que se encuentra el colaborador */}
                {(mostrarDescripcionesBoxes && !!box.colaboradores && box.colaboradores.length > 0) && (
                  <div className="relative group">
                    <InfoCircleFill size={24} />
                    <div className="absolute bottom-full w-[300px] mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-lg">
                      {DESCRIPCIONES_NINEBOX[index]}
                    </div>
                  </div>
                )}
              </div>
              {/* Esta parte es para informe individual cuando se recibe colaborador. */}
              {!!box.colaborador && (
                <div className="flex flex-row justify-center items-center gap-4 mt-2 relative group">
                  <img
                    className="w-10 h-10 rounded-md object-cover"
                    src={box.colaborador.foto_empleado || userDefault}
                    title={box.colaborador.nombre_completo}
                    alt="Foto de perfil"
                  />
                  <p className="rounded-md p-2 bg-black text-white text-sm">
                    Se encuentra aquí
                  </p>
                  <div className="absolute bottom-full w-[300px] mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-lg">
                    {DESCRIPCIONES_NINEBOX[index]}
                  </div>
                </div>
              )}
              {!box.colaborador && !!box.cuadranteActual && (
                <div className="flex justify-center items-center mt-2">
                  <p className="rounded-md p-2 bg-black text-white text-sm">
                    Se encuentra aquí
                  </p>
                </div>
              )}
              {!box.colaborador && !box.cuadranteActual && !!box.colaboradores && box.colaboradores.length >= 0 && (
                <div>
                  {box.colaboradores.length > 0 && (
                    <div
                      className="flex flex-row justify-center items-center gap-2 mt-2 cursor-pointer"
                      onClick={() => onSelectBox(box)}>
                      {box.colaboradores.slice(0, 3).map((colaborador, index) => (
                        <img
                          className="w-10 h-10 rounded-md object-cover"
                          src={colaborador.foto_empleado || userDefault}
                          title={`${colaborador.nombre} ${colaborador.apellido}`}
                          alt="Foto de perfil"
                        />
                      ))}
                      {box.colaboradores.length > 3 && (
                        <p className="rounded-md px-2 py-1 bg-zinc-700 text-white text-sm mt-2">
                          {"+ "}{box.colaboradores.length - 3}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Flecha horizontal */}
        < div >
          <div className="border-2 ml-10 border-[#90EE90]"></div>
          <div className="ml-10 flex justify-between">
            <h1 className="text-xs">Bajo</h1>
            <h1 className="text-xs">Medio</h1>
            <h1 className="text-xs">Alto</h1>
          </div>
        </div>
      </div >
      {/*  */}
      <Modal isOpen={showModal}>
        <div className="rounded-lg bg-white w-6/12">
          <div className="flex flex-col gap-3 p-4">
            <div className="flex flex-row justify-between text-lg font-bold">
              <div>{selectedBox.nombre}</div>
              <div className="cursor-pointer" onClick={() => setShowModal(false)}>
                <XLg />
              </div>
            </div>
            <div className="flex flex-col gap-2 max-h-[400px] custom-scrollbar">
              {
                selectedBox.colaboradores.map((item, idx) => (
                  <ProfileInfoCard
                    key={`col-${idx}`}
                    name={`${item.nombre} ${item.apellido}`}
                    image={item.foto_empleado}
                    limitWidth={false}
                  >
                    <div className="text-sm">{item.cargo ? item.cargo.nombre : "Sin cargo"}</div>
                  </ProfileInfoCard>
                ))
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NineBox;
