import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { kpiActions } from "src/modules/kpi/handlers/redux";
import { formatNumber } from "src/utils/helpers";
import { EyeFill } from "react-bootstrap-icons";
import CollapseCard from "src/modules/common/components/CollapseCard";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { feedbackActions } from "../handlers/redux";
import TabButton from "src/modules/common/components/TabButton";

export function KpisColaboradorSelect(props) {
  const { idColaborador, onSubmit = () => { } } = props;
  const dispatch = useDispatch();

  const kpis_colaborador = useSelector(
    (state) => state.feedback.kpis_colaborador
  );
  const kpis_colaborador_loading = useSelector(
    (state) => state.feedback.kpis_colaborador_loading
  );

  const [activeKpi, setActiveKpi] = useState(null);
  const [selectedKpi, setSelectedKpi] = useState(
    kpis_colaborador ? kpis_colaborador[0] : {}
  );

  useEffect(() => {
    if (idColaborador) {
      dispatch(feedbackActions.getKpisColaborador(idColaborador));
    }
  }, []);

  const onChangeKpi = (idx) => {
    console.log("onChangeKpi", idx);
    setActiveKpi(idx);
    setSelectedKpi(kpis_colaborador[idx]);
  };

  const onAddToFeedback = (kpi) => {
    onSubmit("KPI", kpi);
  };

  const showKpiMeta = (kpi) => {
    if (kpi.metrica.label === "Selección simple") return "Logro/No logro";
    return kpi.meta
      ? `${formatNumber(kpi.meta)}`
      : kpi.rangos.length > 0
        ? "Por escala"
        : "Sin definir";
  };

  const showEscala = (kpi) => {
    const { rangos } = kpi;
    return (
      <div className="group flex justify-center items-center border rounded-md p-1">
        <EyeFill></EyeFill>
        <div className="border rounded-md p-2 bg-white z-10 hidden group-hover:block group-hover:absolute bottom-0 left-28">
          <div className="flex gap-2 font-bold text-sm">
            <div className="flex-1">Desde</div>
            <div className="flex-1">Hasta</div>
            <div className="flex-1">Resultado</div>
          </div>
          {rangos.map((r, i) => (
            <div className="flex gap-2 text-sm">
              <div className="flex-1">
                {r.desde ? r.desde.toLocaleString("de-DE") : r.desde}
              </div>
              <div className="flex-1">
                {r.hasta ? r.hasta.toLocaleString("de-DE") : r.hasta}
              </div>
              <div className="flex-1">
                {r.resultado
                  ? r.resultado.toLocaleString("de-DE")
                  : r.resultado}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderKpisColaboradorList = () => {
    //
    const kpi = kpis_colaborador[activeKpi];
    return (
      <CollapseCard
        key={`kpi-${activeKpi}-${kpi.id}`}
        titulo={kpi.nombre}
        extraEndContent={
          true ? (
            <div className="flex justify-end items-center gap-2 text-sm">
              <input
                disabled={true}
                type="number"
                value={kpi.peso}
                className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2 w-16"
              />
              <div>%</div>
            </div>
          ) : (
            ""
          )
        }
      >
        <div className="flex flex-col gap-2">
          <div>{kpi.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{kpi.periodo.label}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{kpi.metrica.label}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showKpiMeta(kpi)}
                {showKpiMeta(kpi) === "Por escala" && (
                  <div>{showEscala(kpi)}</div>
                )}
              </div>
            </div>
            {/* <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(kpi)}
            </div> */}
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="bg-sky-500 text-white text-sm px-2 py-1 rounded-md"
              onClick={() => onAddToFeedback(kpi)}
            >
              Agregar
            </button>
          </div>
        </div>
      </CollapseCard>
    );
  };

  return (
    <div className="">
      <div className="gap-1 p-1">
        <div className="font-semibold">KPIs</div>
        <div className="text-sm">
          Puede seleccionar un KPI para conocer más detalles
        </div>
        <div className="flex flex-col gap-1 mt-2">
          <div className="flex flex-wrap p-1 custom-scrollbar gap-1">
            {kpis_colaborador &&
              kpis_colaborador.map((kpi, cidx) => (
                <div>
                  <TabButton
                    title={kpi.nombre.length > 50
                      ? kpi.nombre.substring(0, 50) + "..."
                      : kpi.nombre}
                    isActive={cidx === activeKpi}
                    action={() => onChangeKpi(cidx)} />
                </div>
              ))}
          </div>
        </div>
      </div>
      {!kpis_colaborador_loading &&
        kpis_colaborador.length > 0 &&
        activeKpi !== null &&
        renderKpisColaboradorList()}
      {kpis_colaborador_loading && activeKpi !== null && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
    </div>
  );
}
