import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { objetivoApi } from "./api";
import { objetivoCargoActions } from "./redux";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ObjectToString } from "src/utils/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function* objetivosCargoSaga({ payload }) {
  const token = getToken();
  try {
    //
    const data = yield call(objetivoApi(token).asignados);
    yield put(objetivoCargoActions.setObjetivosCargo({ data }));
  } catch (error) {
    console.log({ error });
  }
}

function* subordinadosSaga({ payload }) {
  const token = getToken();
  const subordinadosFilter = yield select(
    (state) => state.objetivoCargo.subordinados_filter
  );
  try {
    //
    const data = yield call(objetivoApi(token).subordinados, {
      subordinados_filter: subordinadosFilter,
    });
    yield put(objetivoCargoActions.setSubordinados({ data }));
  } catch (error) {
    console.log({ error });
  }
}

function* subordinadoSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(objetivoApi(token).subordinado, payload);
    yield put(objetivoCargoActions.setSubordinado(data));
  } catch (error) {
    console.log({ error });
  }
}

function* colaboradorUpdateObjetivosSaga({ payload }) {
  const token = getToken();
  const { id, objetivos } = payload;
  const params = { id: id, objetivos: objetivos };
  //
  try {
    const data = yield call(
      objetivoApi(token).colaboradorObjetivosUpdate,
      params
    );
    yield put(objetivoCargoActions.getSubordinado({ id: payload.id }));
    SuccessToast({ message: "Objetivos del cargo actualizados con éxito!" });
  } catch (error) {
    const data = error.response.data;
    ErrorToast({ message: data.error });
  }
}

function* objetivoCargoColaboradorHistorialSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      objetivoApi(token).objetivoCargoColaboradorHistorial,
      payload
    );
    yield put(objetivoCargoActions.setObjetivoCargoHistorial(data));
  } catch (error) {
    yield put(objetivoCargoActions.setObjetivoCargoHistorial({}));
    const errores = error.response?.data || {};
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

export function* objetivosCargoSagas() {
  yield takeEvery(
    objetivoCargoActions.getObjetivosCargo.type,
    objetivosCargoSaga
  );
  yield takeEvery(objetivoCargoActions.getSubordinados.type, subordinadosSaga);
  yield takeEvery(
    objetivoCargoActions.setSubordinadosFilter.type,
    subordinadosSaga
  );
  yield takeEvery(objetivoCargoActions.getSubordinado.type, subordinadoSaga);
  yield takeEvery(
    objetivoCargoActions.colaboradorUpdateObjetivos.type,
    colaboradorUpdateObjetivosSaga
  );

  yield takeEvery(
    objetivoCargoActions.getObjetivoCargoHistorial.type,
    objetivoCargoColaboradorHistorialSaga
  );
}
