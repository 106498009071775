import React, { useEffect, useState } from "react";
import { Power, TextIndentLeft, TextIndentRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as BeneficioIconInactive } from "src/icons/authenticity-inactive.svg";
import { ReactComponent as BeneficioIcon } from "src/icons/authenticity.svg";
import { ReactComponent as EmployeeIcon } from "src/icons/employees.svg";
import { ReactComponent as EmployeeIconInactive } from "src/icons/employees_inactive.svg";
import { ReactComponent as EvaluationIcon } from "src/icons/evaluation.svg";
import { ReactComponent as EvaluationIconInactive } from "src/icons/evaluation_inactive.svg";
import { ReactComponent as HomeIcon } from "src/icons/home.svg";
import { ReactComponent as HomeIconInactive } from "src/icons/home_inactive.svg";
import { ReactComponent as KpiIcon } from "src/icons/kpi.svg";
import { ReactComponent as KpiIconInactive } from "src/icons/kpi_inactive.svg";
import { ReactComponent as MdiPenInactive } from "src/icons/mdi_pen-inactive.svg";
import { ReactComponent as MdiPen } from "src/icons/mdi_pen.svg";
import { ReactComponent as ObjetivoCargoIcon } from "src/icons/objetivo_cargo.svg";
import { ReactComponent as ObjetivoCargoInactiveIcon } from "src/icons/objetivo_cargo_inactive.svg";
import { ReactComponent as PlanCarreraIcon } from "src/icons/plan_carrera.svg";
import { ReactComponent as PlanCarreraIconInactive } from "src/icons/plan_carrera_inactive.svg";
import { ReactComponent as TeamIcon } from "src/icons/team.svg";
import { ReactComponent as TeamIconInactive } from "src/icons/team_inactive.svg";
import { ReactComponent as PermisoIcon } from "src/icons/permiso_icon.svg";
import { ReactComponent as PermisoIconInactive } from "src/icons/permiso_icon_inactive.svg";
import { ReactComponent as EncuestaIcon } from "src/icons/encuesta.svg";
import logo2 from "src/images/logo2.svg";
import { authActions } from "src/modules/auth/handlers/redux";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import {
  deleteCookie,
  deleteUserFromCookie,
  getUserFromCookie,
} from "src/modules/common/handler/helpers";
import { commonActions } from "src/modules/common/handler/redux";
import "../css/Sidebar.css";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ADMIN_APP_URL =
    process.env.REACT_APP_ADMIN_APP_URL || "http://localhost:3000/";
  const [activeIndex, setActiveIndex] = useState(0);
  const usuario = getUserFromCookie();
  const user = useSelector((state) => state.auth.user);
  const showSidebar = useSelector((state) => state.common.showSidebar);
  const toggleSidebar = () => {
    dispatch(commonActions.showSidebar(!showSidebar));
  };
  const getToggleStyle = () => {
    if (showSidebar) return "w-60";
    return "w-20";
  };

  function formatUserName(user) {
    if (!user || !user.nombre || !user.apellido) {
      return '';
    }

    const firstName = user.nombre.split(' ')[0];
    const firstLastNameInitial = user.apellido.split(' ')[0][0];
    return `${firstName} ${firstLastNameInitial}.`;
  }

  const getSidebarBtn = () => {
    if (showSidebar) return <TextIndentRight size={21}></TextIndentRight>;
    return <TextIndentLeft size={21}></TextIndentLeft>;
  };
  const hideStyle = (displayStyle) => {
    if (!showSidebar) return "hidden";
    return displayStyle ? displayStyle : "block";
  };
  const URL_BASE = process.env.REACT_APP_URL || "http://localhost:8000";
  //
  const getNavLinks = () => {
    const {
      permiso_kpi = true,
      permiso_objetivo = true,
      permiso_evaluacion = true,
      permiso_plan_carrera = true,
      permiso_beneficio = true,
      permiso_escala_salarial = true,
      permiso_feedback = true,
      permiso_permiso = true,
      permiso_encuesta = true,
    } = usuario;
    let navLinks = [
      {
        name: "Inicio",
        to: "/inicio",
      },
      {
        name: "Mi equipo",
        to: "/equipo",
      },
      {
        name: "Colaboradores",
        to: "/colaboradores",
      },
    ];
    if (!!permiso_kpi) {
      navLinks.push({
        name: "KPIs",
        to: "/kpis",
      });
    }
    if (!!permiso_objetivo) {
      navLinks.push({
        name: "Objetivos",
        to: "/objetivo-cargo",
      });
    }
    if (!!permiso_evaluacion) {
      navLinks.push({
        name: "Evaluaciones",
        to: "/evaluaciones",
      });
    }
    if (!!permiso_plan_carrera) {
      navLinks.push({
        name: "Plan de Carrera",
        to: "/plan-carrera",
      });
    }
    if (!!permiso_beneficio) {
      navLinks.push({
        name: "Beneficios",
        to: "/beneficio",
      });
    }
    if (!!permiso_feedback) {
      navLinks.push({
        name: "Feedback",
        to: "/feedback",
      });
    }
    if (!!permiso_permiso) {
      navLinks.push({
        name: "Permisos",
        to: "/permisos",
      });
    }
    if (!!permiso_encuesta) {
      navLinks.push({
        name: "Encuestas",
        to: "/encuestas",
      });
    }
    if (!!permiso_escala_salarial) {
      // TODO pending, no hay modulo pero ver que se podria limitar...
    }
    //
    return navLinks;
  };

  const inicioData = useSelector((state) => state.inicio.inicios) || {};

  const goToMyProfile = () => {
    navigate("/mi-perfil/");
  };

  const getMenuIcon = (index) => {
    const menuIcons = [
      activeIndex === 0 ? (
        <HomeIcon className={`m-2`}></HomeIcon>
      ) : (
        <HomeIconInactive className={`m-2`}></HomeIconInactive>
      ),
      activeIndex === 1 ? (
        <TeamIcon className={`m-2`}></TeamIcon>
      ) : (
        <TeamIconInactive className={`m-2`}></TeamIconInactive>
      ),
      activeIndex === 2 ? (
        <EmployeeIcon className={`m-2`}></EmployeeIcon>
      ) : (
        <EmployeeIconInactive className={`m-2`}></EmployeeIconInactive>
      ),
      activeIndex === 3 ? (
        <KpiIcon className={`m-2`}></KpiIcon>
      ) : (
        <KpiIconInactive className={`m-2`}></KpiIconInactive>
      ),
      activeIndex === 4 ? (
        <ObjetivoCargoIcon className={`m-2`}></ObjetivoCargoIcon>
      ) : (
        <ObjetivoCargoInactiveIcon
          className={`m-2`}
        ></ObjetivoCargoInactiveIcon>
      ),
      activeIndex === 5 ? (
        <EvaluationIcon className={`mx-2.5 my-2.5`}></EvaluationIcon>
      ) : (
        <EvaluationIconInactive className={`m-2.5`}></EvaluationIconInactive>
      ),
      activeIndex === 6 ? (
        <PlanCarreraIcon className={`mx-2.5 my-2.5`}></PlanCarreraIcon>
      ) : (
        <PlanCarreraIconInactive className={`m-2.5`}></PlanCarreraIconInactive>
      ),
      activeIndex === 7 ? (
        <BeneficioIcon className={`mx-2.5 my-2.5`}></BeneficioIcon>
      ) : (
        <BeneficioIconInactive className={`m-2.5`}></BeneficioIconInactive>
      ),
      activeIndex === 8 ? (
        <MdiPen className={`mx-2.5 my-2.5`}></MdiPen>
      ) : (
        <MdiPenInactive className={`m-2.5`}></MdiPenInactive>
      ),
      activeIndex === 9 ? (
        <PermisoIcon className={`mx-2.5 my-2.5`}></PermisoIcon>
      ) : (
        <PermisoIconInactive className={`m-2.5`}></PermisoIconInactive>
      ),
      activeIndex === 10 ? (
        <EncuestaIcon className={`mx-2.5 my-2.5`}></EncuestaIcon>
      ) : (
        <EncuestaIcon className={`m-2.5`}></EncuestaIcon>
      ),
    ];
    return menuIcons[index] || <div></div>;
  };

  useEffect(() => {
    dispatch(authActions.getUser(usuario.colaborador_id));
  }, [user.foto_empleado]);

  return (
    <nav
      className={`flex flex-col py-4 ${getToggleStyle()} gap-2 bg-zinc-900 text-white`}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-col px-4">
          <div className="flex flex-row justify-between">
            <img className="sidebar-logo h-6 " src={logo2} alt="logo" />
            <div className="" onClick={toggleSidebar}>
              {getSidebarBtn()}
            </div>
          </div>
        </div>
        <div
          className="flex flex-row gap-3 hover:cursor-pointer"
          onClick={goToMyProfile}
        >
          <div className="pl-6">
            {!user?.foto_empleado && (
              <ProfileImage className="h-10 w-10 p-2"></ProfileImage>
            )}

            {!!user?.foto_empleado && (
              <div className="w-10 bg-slate-200 rounded-md">
                <img
                  className="object-cover h-10 w-10 rounded-md"
                  src={user?.foto_empleado}
                  alt={`Foto Jefe de Lider`}
                />
              </div>
            )}
          </div>
          <div className={hideStyle()}>
            <div className="text-sm">{formatUserName(user)}</div>
            <div className="text-xs text-blue-500">Mi perfil</div>
          </div>
        </div>
        {!!usuario.es_admin && (
          <div className="flex justify-center">
            <a className="text-sm" href={ADMIN_APP_URL} target={`_blank`}>
              Ir al Admin
            </a>
          </div>
        )}
      </div>
      <div className="border-b-2 border-b-zinc-800"></div>
      {/* LINKS */}
      <div className="flex flex-col overflow-y-auto custom-scrollbar">
        {getNavLinks().map((link, index) => (
          <NavLink
            {...link}
            key={`link-${index}`}
            className={({ isActive }) => {
              if (isActive) setActiveIndex(index);
              return `${!!isActive ? "bg-white bg-opacity-50" : ""
                }  text-white no-underline hover:bg-white hover:bg-opacity-50 hover:font-bold hover:text-sky-500 w-100`;
            }}
          >
            <div className="flex flex-row items-center py-2 px-4 gap-3 link-container">
              <div className="rounded bg-zinc-800 w-10 h-10 justify-center items-center">
                {getMenuIcon(index)}
              </div>
              <div className={hideStyle("text-sm link-parent")}>
                {link.name}
              </div>
            </div>
          </NavLink>
        ))}
        {/*  */}
        <div
          onClick={() => {
            deleteCookie("token", "/", ".wel.com.py");
            deleteCookie("token", "/"); // localhost
            deleteCookie("kento", "/", ".wel.com.py");
            deleteCookie("kento", "/"); // localhost
            deleteUserFromCookie(".wel.com.py");
            deleteUserFromCookie(); // localhost
            navigate("/auth/login/");
          }}
          className={hideStyle(
            `text-white no-underline hover:cursor-pointer hover:bg-white hover:bg-opacity-50 hover:font-bold hover:text-sky-500`
          )}
        >
          <div className="flex flex-row items-center py-2 px-4 gap-3">
            <div className="rounded bg-zinc-800 w-10 h-10">
              <Power size={24} className="m-2"></Power>
            </div>
            <div className="text-sm">Cerrar sesión</div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
