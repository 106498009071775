import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { commonActions } from "src/modules/common/handler/redux";
import backButton from "src/modules/common/components/images/back-arrow.png";
import {
  asignacionRespuestasDefault,
  encuestaActions,
  encuestaDefault,
} from "../handlers/redux";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import dayjs from "dayjs";
import { validarRespuestas } from "../helpers/helper";
import EtiquetaComponent from "../components/EtiquetaComponent";

const EncuestaColaborador = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { asignacionId } = params;

  const asignacion = useSelector((state) => state.encuesta.asignacion);
  const { encuesta = encuestaDefault } = asignacion;
  const { completado = true } = asignacion;
  const asignacion_loading = useSelector(
    (state) => state.encuesta.asignacion_loading
  );
  const respuestas_asignacion = useSelector(
    (state) => state.encuesta.respuestas_asignacion
  );
  const respuestas_asignacion_loading = useSelector(
    (state) => state.encuesta.respuestas_asignacion_loading
  );

  const [asignacionRespuestas, setAsignacionRespuestas] = useState(
    asignacionRespuestasDefault
  );
  const [showDescripcion, setShowDescripcion] = useState(false);

  useEffect(() => {
    dispatch(commonActions.setTitle("Encuesta"));
    dispatch(commonActions.showSidebar(false));
    if (asignacionId) {
      dispatch(encuestaActions.getAsignacion(asignacionId));
      dispatch(encuestaActions.getRespuestasAsignacion(asignacionId));
    }
  }, []);

  useEffect(() => {
    if (respuestas_asignacion) {
      setAsignacionRespuestas(respuestas_asignacion);
    }
  }, [respuestas_asignacion]);

  const isDisabled = () => {
    return completado || !encuesta.estado;
  };

  const onBack = () => {
    return (
      <div
        onClick={() => navigate("/encuestas")}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };

  const handleInputChange =
    (index, opcionIndex = null) =>
      (e) => {
        const asignacionRespuestasCopy = JSON.parse(
          JSON.stringify(asignacionRespuestas)
        );
        const preguntaId = encuesta.preguntas[index].id;
        // Buscar si ya existe una respuesta para la pregunta
        const respuestaExistente = asignacionRespuestasCopy.respuestas.find(
          (respuesta) => respuesta.pregunta_id === preguntaId
        );

        // Si opcionIndex viene null, es porque se respondió texto o fecha, si viene distinto de null es porque se respondió una opción
        if (opcionIndex === null) {
          const { name, value } = e.target;
          if (respuestaExistente) {
            // Si ya existe, actualizamos
            respuestaExistente[name] = value;
          } else {
            // Si no existe, agregamos una nueva respuesta
            const nuevaRespuesta = { pregunta_id: preguntaId, [name]: value };
            asignacionRespuestasCopy.respuestas.push(nuevaRespuesta);
          }
        } else {
          const { name, checked, type } = e.target;
          const opcionId = encuesta.preguntas[index].opciones[opcionIndex].id;
          if (respuestaExistente) {
            if (respuestaExistente[name].includes(opcionId)) {
              if (!checked) {
                respuestaExistente[name] = respuestaExistente[name].filter(
                  (id) => id !== opcionId
                );
              }
            } else {
              if (checked) {
                if (type === "checkbox") {
                  respuestaExistente[name].push(opcionId);
                } else {
                  respuestaExistente[name] = [opcionId];
                }
              }
            }
          } else {
            // Si no existe, agregamos una nueva respuesta
            const nuevaRespuesta = {
              pregunta_id: preguntaId,
              [name]: [opcionId],
            };
            asignacionRespuestasCopy.respuestas.push(nuevaRespuesta);
          }
        }
        setAsignacionRespuestas(asignacionRespuestasCopy);
      };

  const getRespuesta = (preguntaId, name) => {
    if (!asignacionRespuestas || !asignacionRespuestas.respuestas) return null;
    const respuesta = asignacionRespuestas.respuestas.find(
      (r) => r.pregunta_id === preguntaId
    );
    if (name === "respuesta_fecha") {
      return respuesta
        ? respuesta[name]
          ? dayjs(respuesta[name]).format("YYYY-MM-DDTHH:mm")
          : null
        : null;
    }
    return respuesta ? respuesta[name] : null;
  };

  const isOpcionSeleccionada = (preguntaId, opcionId, name) => {
    const opcionesSeleccionadas = getRespuesta(preguntaId, name);
    return opcionesSeleccionadas
      ? opcionesSeleccionadas.includes(opcionId)
      : false;
  };

  const onSubmit = () => {
    if (validarRespuestas(asignacion.encuesta, asignacionRespuestas)) {
      dispatch(
        encuestaActions.guardarRespuestasAsignacion(asignacionRespuestas)
      );
    }
  };

  return (
    <>
      <Header
        titulo="Encuesta"
        className={"bg-white"}
        LeftComponent={onBack}
      ></Header>
      {!asignacion_loading && !respuestas_asignacion_loading && (
        <div className="container mx-auto p-4 w-2/3">
          <h1 className="text-xl font-bold">{encuesta.nombre}</h1>
          <span
            className="text-sm text-gray-500 cursor-pointer hover:text-gray-700 font-bold mx-2"
            onClick={() => setShowDescripcion(!showDescripcion)}
          >
            {showDescripcion
              ? "Ocultar descripción"
              : "Mostrar descripción"}
          </span>
          {showDescripcion && (
            <p className="flex whitespace-pre-wrap m-2 text-sm">{encuesta.descripcion || "Sin descripción"}</p>
          )}
          <div className="border-b mb-8"></div>
          {!!asignacion.etiquetas &&
            asignacion.etiquetas.length > 0 &&
            isDisabled() && (
              <div className="mb-8">
                <h2 className="text-md font-semibold mb-2">
                  Etiquetas asignadas
                </h2>
                <div className="flex flex-wrap gap-2">
                  {asignacion.etiquetas.map((etiqueta) => (
                    <EtiquetaComponent
                      key={etiqueta.id}
                      name={etiqueta.nombre}
                      color={etiqueta.color}
                    />
                  ))}
                </div>
              </div>
            )}

          {encuesta.preguntas.map((pregunta, index) => (
            <div
              key={pregunta.id}
              className="border rounded-2xl p-4 mb-4 shadow-md bg-white"
            >
              <h2 className="text-sm font-semibold mb-2">
                Pregunta {index + 1}
              </h2>
              <p className="font-bold mb-1">{pregunta.texto}</p>
              <p className="text-sm mb-2 px-2 flex whitespace-pre-wrap text-gray-700">{pregunta.descripcion}</p>
              {(pregunta.tipo_respuesta === "SIMPLE" ||
                pregunta.tipo_respuesta === "MULTIPLE") && (
                  <div className="flex flex-col space-y-2">
                    <label className="text-gray-700 font-semibold text-sm">
                      {pregunta.tipo_respuesta === "MULTIPLE"
                        ? "Selecciona las opciones de respuesta"
                        : "Selecciona una opcion de respuesta"}
                    </label>
                    {pregunta.opciones.map((opcion, opcionIndex) => (
                      <div
                        key={opcion.id}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type={
                            pregunta.tipo_respuesta === "MULTIPLE"
                              ? "checkbox"
                              : "radio"
                          }
                          id={`opcion-${opcion.id}`}
                          name={`respuesta_opciones`}
                          className="w-4 h-4"
                          onChange={handleInputChange(index, opcionIndex)}
                          checked={isOpcionSeleccionada(
                            pregunta.id,
                            opcion.id,
                            "respuesta_opciones"
                          )}
                          disabled={isDisabled()}
                        />
                        <label
                          htmlFor={`opcion-${opcion.id}`}
                          className="text-gray-700"
                        >
                          {opcion.texto}
                        </label>
                      </div>
                    ))}
                  </div>
                )}

              {pregunta.tipo_respuesta === "TEXTO" && (
                <div className="flex flex-col space-y-2 w-full">
                  <textarea
                    name="respuesta_texto"
                    placeholder="Escriba aquí..."
                    className="border rounded border-zinc-200 px-2 py-3 w-full"
                    value={getRespuesta(pregunta.id, "respuesta_texto") || ""}
                    onChange={handleInputChange(index)}
                    disabled={isDisabled()}
                  />
                </div>
              )}

              {pregunta.tipo_respuesta === "FECHA" && (
                <div className="flex flex-col space-y-2 w-1/2">
                  <label className="text-gray-700 font-semibold">
                    Selecciona una fecha
                  </label>
                  <input
                    type="datetime-local"
                    name={`respuesta_fecha`}
                    className="border rounded px-2 py-2 w-full"
                    value={getRespuesta(pregunta.id, "respuesta_fecha") || ""}
                    onChange={handleInputChange(index)}
                    disabled={isDisabled()}
                  />
                </div>
              )}
            </div>
          ))}
          {!isDisabled() && (
            <PrimaryButton className="w-full" onClick={onSubmit}>
              Enviar respuestas
            </PrimaryButton>
          )}
        </div>
      )}
      {(asignacion_loading || respuestas_asignacion_loading) && (
        <div className="h-full w-full flex items-center justify-center">
          <LoadingSpinButton />
        </div>
      )}
    </>
  );
};

export default EncuestaColaborador;
