import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
export const equipoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    equipo: async () => {
      console.info("equipo api");
      try {
        const equipo = await axios.get(`${API_URL}/colaborador/equipo/`, {
          headers,
        });
        return equipo.data;
      } catch (error) {
        console.log("equipo error", error);
        throw error;
      }
    },
    subordinados: async ({ subordinados_filter }) => {
      const { nombre = "" } = subordinados_filter;
      try {
        const subordinados = await axios.get(
          `${API_URL}/colaborador/subordinados/?search=${nombre}`,
          {
            headers,
          }
        );
        return subordinados.data;
      } catch (error) {
        console.log("subordinados error", error);
        throw error;
      }
    },
    subordinadosColaborador: async ({ colaboradorId, subordinados_filter }) => {
      const { nombre = "" } = subordinados_filter;
      try {
        const subordinados = await axios.get(
          `${API_URL}/colaborador/${colaboradorId}/subordinados/?search=${nombre}`,
          {
            headers,
          }
        );
        return subordinados.data;
      } catch (error) {
        console.log("subordinados error", error);
        throw error;
      }
    },
    equipoNinebox: async () => {
      const response = await axios.get(`${API_URL}/evaluacion/equipo/ninebox/detail`, {
        headers,
      })
      return response.data
    },
    equipoInformeEvaluaciones: async () => {
      const response = await axios.get(`${API_URL}/evaluacion/equipo/informe/evaluaciones`, {
        headers,
      })
      return response.data
    }
  };
};
