import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken } from "src/modules/common/handler/helpers";
import { commonActions } from "src/modules/common/handler/redux";
import { ObjectToString } from "src/utils/helpers";
import { kpiApi } from "./api";
import { kpiActions } from "./redux";

function* kpisSaga({ payload }) {
  const token = getToken();
  try {
    //
    const data = yield call(kpiApi(token).asignados);
    yield put(kpiActions.setKpis({ data }));
  } catch (error) {
    console.log({ error });
  }
}

function* subordinadosSaga({ payload }) {
  const token = getToken();
  const subordinadosFilter = yield select(
    (state) => state.kpi.subordinados_filter
  );
  try {
    //
    const data = yield call(kpiApi(token).subordinados, {
      subordinados_filter: subordinadosFilter,
    });
    yield put(kpiActions.setSubordinados({ data }));
  } catch (error) {
    console.log({ error });
  }
}

function* subordinadoSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(kpiApi(token).subordinado, payload);
    yield put(kpiActions.setSubordinado(data));
  } catch (error) {
    console.log({ error });
  }
}

function* kpiSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).detail, { id: payload });
    yield put(kpiActions.setKpi(data));
  } catch (error) {
    console.log({ error });
  }
}

function* kpiHistorialSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).kpiColaboradorHistorial, payload);
    yield put(kpiActions.setKpiHistorial(data));
  } catch (error) {
    yield put(kpiActions.setKpiHistorial({}));
    const errores = error.response?.data || {};
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiCreateSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const colaborador = yield select((state) => state.kpi.subordinado);
  const params = { ...kpi };
  try {
    const data = yield call(kpiApi(token).create, params);
    //const newKPI = data.data;
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(kpiActions.subordinado({ id: colaborador.id }));
  } catch (error) {
    yield put(kpiActions.kpiCreateSuccess());
    console.log({ error });
  }
}

function* kpiUpdateSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const colaborador = yield select((state) => state.kpi.subordinado);
  const params = { ...kpi };
  try {
    const data = yield call(kpiApi(token).update, params);
    yield put(kpiActions.kpiUpdateSuccess());
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(kpiActions.subordinado({ id: colaborador.id }));
    // yield put(kpiActions.kpis());
  } catch (error) {
    yield put(kpiActions.kpiUpdateSuccess());
    console.log({ error });
  }
}

function* kpiDeleteSaga({ payload }) {
  const token = getToken();
  const colaborador = yield select((state) => state.kpi.subordinado);
  console.log({ payload });
  try {
    const data = yield call(kpiApi(token).delete, { id: payload });
    yield put(kpiActions.subordinado({ id: colaborador.id }));
    yield put(kpiActions.kpiDeleteSuccess());
  } catch (error) {
    yield put(kpiActions.kpiDeleteSuccess());
    console.log({ error });
  }
}
// ANOTACIONES //
function* getAnotacionesSaga({ payload }) {
  const token = getToken();
  const kpi_anotaciones = yield select((state) => state.kpi.kpi_anotaciones);
  const anotaciones_filter = yield select(
    (state) => state.kpi.anotaciones_filter
  );
  //
  try {
    const data = yield call(kpiApi(token).anotacionList, {
      id: kpi_anotaciones.id,
      anotaciones_filter: anotaciones_filter,
    });
    yield put(kpiActions.setAnotaciones(data.results));
  } catch (error) {
    console.log({ error });
  }
}

function* createAnotacionSaga({ payload }) {
  const token = getToken();
  const anotacion = yield select((state) => state.kpi.anotacion);
  const kpi = yield select((state) => state.kpi.kpi);
  try {
    const data = yield call(kpiApi(token).anotacionCreate, anotacion);
    //
    yield put(kpiActions.getAnotaciones(kpi));
    yield put(kpiActions.createAnotacionComplete());
    //
    SuccessToast({ message: "Anotación agregada con éxito!" });
  } catch (error) {
    console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* updateAnotacionSaga({ payload }) {
  const token = getToken();
  const anotacion = yield select((state) => state.kpi.anotacion);
  const kpi = yield select((state) => state.kpi.kpi);
  try {
    const data = yield call(kpiApi(token).anotacionUpdate, anotacion);
    //
    yield put(kpiActions.getAnotaciones(kpi));
    yield put(kpiActions.updateAnotacionComplete());
    // yield put(kpiActions.setAnotacion({}));
    SuccessToast({ message: "Anotación actualizada con éxito!" });
  } catch (error) {
    console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* deleteAnotacionSaga({ payload }) {
  const token = getToken();
  const anotacion = yield select((state) => state.kpi.anotacion);
  const kpi = yield select((state) => state.kpi.kpi);
  try {
    const data = yield call(kpiApi(token).anotacionDelete, {
      id: anotacion.id,
    });
    yield put(kpiActions.getAnotaciones(kpi));
    yield put(kpiActions.setAnotacion({}));
    SuccessToast({ message: "Anotación eliminada con éxito!" });
  } catch (error) {
    console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* deleteAnotacionesSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const anotaciones_checked = yield select(
    (state) => state.kpi.anotaciones_checked
  );
  //
  try {
    const data = yield call(kpiApi(token).anotacionesDelete, {
      anotacionesIds: anotaciones_checked,
    });
    //
    yield put(kpiActions.resetCheckAnotaciones());
    yield put(kpiActions.getAnotaciones(kpi));
    SuccessToast({ message: "Anotaciones eliminadas con éxito!" });
  } catch (error) {
    console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// SELECTORES //
function* metricasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).metricaslist);
    yield put(kpiActions.setMetricas({ metricas: data }));
  } catch (error) {
    console.log({ error });
  }
}

function* periodosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).periodoslist);
    yield put(kpiActions.setPeriodos({ periodos: data }));
  } catch (error) {
    console.log({ error });
  }
}

function* colaboradorKpisUpdateSaga({ payload }) {
  const token = getToken();
  const { id, kpis, archivados = [] } = payload;
  const params = { id: id, kpis: kpis, archivados: archivados };
  //
  try {
    const data = yield call(kpiApi(token).colaboradorKpisUpdate, params);
    yield put(kpiActions.getColaboradorKpisNoVigentes({ id: id }));
    const response = yield call(kpiApi(token).subordinado, { id: id });
    yield put(kpiActions.setSubordinado(response));
    SuccessToast({ message: "KPIs actualizados con éxito!" });
  } catch (error) {
    const data = error.response.data;
    ErrorToast({ message: data.error });
  }
}

function* colaboradorKpisNoVigentesSaga({ payload }) {
  const token = getToken();
  const colaboradorKpis_filter = yield select(
    (state) => state.kpi.colaboradorKpisNoVigentes_filter
  );
  try {
    const data = yield call(kpiApi(token).subordinadoKpisNoVigentesList, {
      ...payload,
      colaboradorKpis_filter: colaboradorKpis_filter,
    });
    yield put(kpiActions.setColaboradorKpisNoVigentes(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

export function* kpiSagas() {
  yield takeEvery(kpiActions.getKpi.type, kpiSaga);
  yield takeEvery(kpiActions.getKpiHistorial.type, kpiHistorialSaga);
  yield takeEvery(kpiActions.kpiCreate.type, kpiCreateSaga);
  yield takeEvery(kpiActions.kpiUpdate.type, kpiUpdateSaga);
  yield takeEvery(kpiActions.kpiDelete.type, kpiDeleteSaga);
  //
  yield takeEvery(kpiActions.kpis.type, kpisSaga);
  yield takeEvery(kpiActions.subordinados.type, subordinadosSaga);
  yield takeEvery(kpiActions.setSubordinadosFilter.type, subordinadosSaga);
  yield takeEvery(kpiActions.subordinado.type, subordinadoSaga);
  yield takeEvery(kpiActions.metricas.type, metricasSaga);
  yield takeEvery(kpiActions.periodos.type, periodosSaga);
  //
  yield takeEvery(kpiActions.createAnotacion.type, createAnotacionSaga);
  yield takeEvery(kpiActions.updateAnotacion.type, updateAnotacionSaga);
  yield takeEvery(kpiActions.getAnotaciones.type, getAnotacionesSaga);
  yield takeEvery(kpiActions.setAnotacionesFilter.type, getAnotacionesSaga);
  yield takeEvery(kpiActions.deleteAnotacion.type, deleteAnotacionSaga);
  yield takeEvery(kpiActions.deleteAnotaciones.type, deleteAnotacionesSaga);
  // NO VIGENTES
  yield takeEvery(
    kpiActions.getColaboradorKpisNoVigentes.type,
    colaboradorKpisNoVigentesSaga
  );
  yield takeLatest(
    kpiActions.setColaboradorKpisNoVigentesFilter.type,
    colaboradorKpisNoVigentesSaga
  );

  //
  yield takeEvery(
    kpiActions.colaboradorUpdateKpis.type,
    colaboradorKpisUpdateSaga
  );
}
