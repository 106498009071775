import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    // Los feedbacks que debe realizar
    feedbacks_jefe_directo: [],
    feedbacks_jefe_directo_loading: false,
    feedbacks_jefe_directo_count: 0,
    feedbacks_jefe_directo_filter: {},
    // Los feedbacks que debe comentar (responder)
    feedbacks_colaborador: [],
    feedbacks_colaborador_loading: false,
    feedbacks_colaborador_count: 0,
    feedbacks_colaborador_filter: {},
    //
    feedback: {},
    feedback_loading: false,
    // Datos de un jefe directo en específico (ColaboradorFeedback)
    jefe_directo: {},
    jefe_directo_loading: false,
    // Datos de un colaborador en específico (ColaboradorFeedback)
    colaborador_feedback: {},
    colaborador_feedback_loading: false,
    // Los colaboradores que fueron seleccionados al crear el feedback
    subordinados_seleccionados: [],
    subordinados_seleccionados_loading: false,
    // Los detalles del feedback que se le hace a un colaborador
    feedback_nomina: {},
    feedback_nomina_loading: false,
    //
    competencias_generales: [],
    competencias_generales_loading: false,
    //
    competencias_areas: [],
    competencias_areas_loading: false,
    //
    competencias_cargos: [],
    competencias_cargos_loading: false,
    // Los kpis del colaborador que se le hará el feedback para listarlos en el modal en etapa Jefe Directo
    kpis_colaborador: [],
    kpis_colaborador_loading: false,
  },
  reducers: {
    getFeedbacksJefeDirecto: (state, { payload = {} }) => {
      state.feedbacks_jefe_directo_loading = true;
    },
    setFeedbacksJefeDirecto: (state, { payload = {} }) => {
      state.feedbacks_jefe_directo = payload;
      state.feedbacks_jefe_directo_loading = false;
    },
    setFeedbacksJefeDirectoCount: (state, { payload = {} }) => {
      state.feedbacks_jefe_directo_count = payload;
    },
    setFeedbacksJefeDirectoFilter: (state, { payload = {} }) => {
      state.feedbacks_jefe_directo_filter = payload;
    },
    //
    getFeedbacksColaborador: (state, { payload = {} }) => {
      state.feedbacks_colaborador_loading = true;
    },
    setFeedbacksColaborador: (state, { payload = {} }) => {
      state.feedbacks_colaborador = payload;
      state.feedbacks_colaborador_loading = false;
    },
    setFeedbacksColaboradorCount: (state, { payload = {} }) => {
      state.feedbacks_colaborador_count = payload;
    },
    setFeedbacksColaboradorFilter: (state, { payload = {} }) => {
      state.feedbacks_colaborador_filter = payload;
    },
    //
    setFeedback: (state, { payload = {} }) => {
      state.feedback = payload;
      state.feedback_loading = false;
    },
    getFeedback: (state, { payload = {} }) => {
      state.feedback_loading = true;
    },
    updateFeedback: (state, { payload = {} }) => {
      state.feedback_loading = true;
    },
    createFeedback: (state, { payload = {} }) => {
      state.feedback_loading = true;
    },
    //
    setJefeDirecto: (state, { payload = {} }) => {
      state.jefe_directo = payload;
      state.jefe_directo_loading = false;
    },
    getJefeDirecto: (state, { payload = {} }) => {
      state.jefe_directo_loading = true;
    },
    //
    setColaboradorFeedback: (state, { payload = {} }) => {
      state.colaborador_feedback = payload;
      state.colaborador_feedback_loading = false;
    },
    getColaboradorFeedback: (state, { payload = {} }) => {
      state.colaborador_feedback_loading = true;
    },
    //
    getSubordinadosSeleccionados: (state, { payload = {} }) => {
      state.subordinados_seleccionados_loading = true;
    },
    setSubordinadosSeleccionados: (state, { payload = {} }) => {
      state.subordinados_seleccionados = payload;
      state.subordinados_seleccionados_loading = false;
    },
    //
    setFeedbackNomina: (state, { payload = {} }) => {
      state.feedback_nomina = payload;
      state.feedback_nomina_loading = false;
    },
    getFeedbackNomina: (state, { payload = {} }) => {
      state.feedback_nomina_loading = true;
    },
    createFeedbackNomina: (state, { payload = {} }) => { },
    deleteFeedbackNomina: (state, { payload = {} }) => { },
    //
    setCompetenciasGenerales: (state, { payload = {} }) => {
      state.competencias_generales = payload;
      state.competencias_generales_loading = false;
    },
    getCompetenciasGenerales: (state, { payload = {} }) => {
      state.competencias_generales_loading = true;
    },
    //
    setCompetenciasAreas: (state, { payload = {} }) => {
      state.competencias_areas = payload;
      state.competencias_areas_loading = false;
    },
    getCompetenciasAreas: (state, { payload = {} }) => {
      state.competencias_areas_loading = true;
    },
    //
    setCompetenciasCargos: (state, { payload = {} }) => {
      state.competencias_cargos = payload;
      state.competencias_cargos_loading = false;
    },
    getCompetenciasCargos: (state, { payload = {} }) => {
      state.competencias_cargos_loading = true;
    },
    //
    setKpisColaborador: (state, { payload = {} }) => {
      state.kpis_colaborador = payload;
      state.kpis_colaborador_loading = false;
    },
    getKpisColaborador: (state, { payload = {} }) => {
      state.kpis_colaborador_loading = true;
    },
  },
});

export const feedbackActions = feedbackSlice.actions;
export const feedbackReducer = feedbackSlice.reducer;
