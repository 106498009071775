import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { equipoApi } from "./api";
import { equipoActions } from "./redux";

function* equipoSaga({ payload }) {
  console.info("equipoSaga");
  const token = getToken();
  try {
    const data = yield call(equipoApi(token).equipo);
    yield put(equipoActions.setEquipo(data));
    console.info("equipoSaga data", data);
  } catch (error) {
    console.log({ error });
  }
}

function* subordinadosSaga({ payload }) {
  const token = getToken();
  const subordinadosFilter = yield select(
    (state) => state.equipo.subordinados_filter
  );
  try {
    //
    const data = yield call(equipoApi(token).subordinados, {
      subordinados_filter: subordinadosFilter,
    });
    yield put(equipoActions.setSubordinados({ data }));
    yield put(equipoActions.setSubordinadosCount(data.count));
  } catch (error) {
    console.log({ error });
  }
}

function* subordinadosColaboradorSaga({ payload }) {
  const token = getToken();
  const subordinadosFilter = yield select(
    (state) => state.equipo.subordinados_colaborador_filter
  );
  try {
    //
    const data = yield call(equipoApi(token).subordinadosColaborador, {
      colaboradorId: payload.colaboradorId || payload,
      subordinados_filter: subordinadosFilter,
    });
    yield put(equipoActions.setSubordinadosColaborador({ data }));
    yield put(equipoActions.setSubordinadosColaboradorCount(data.count));
  } catch (error) {
    console.log({ error });
  }
}

function* equipoNineboxSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(equipoApi(token).equipoNinebox);
    yield put(equipoActions.setEquipoNineboxData(data));
  } catch (error) {
    console.log({ error });
    yield put(equipoActions.setEquipoNineboxData([]));
  }
}

function* equipoInformeEvaluacionesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(equipoApi(token).equipoInformeEvaluaciones);
    yield put(equipoActions.setEquipoInformeEvaluaciones(data));
  } catch (error) {
    console.log({ error });
    yield put(equipoActions.setEquipoInformeEvaluaciones([]));
  }
}

export function* equipoSagas() {
  yield takeEvery(equipoActions.equipo.type, equipoSaga);
  yield takeEvery(equipoActions.subordinados.type, subordinadosSaga);
  yield takeEvery(equipoActions.setSubordinadosFilter.type, subordinadosSaga);
  //
  yield takeEvery(
    equipoActions.subordinadosColaborador.type,
    subordinadosColaboradorSaga
  );
  yield takeEvery(
    equipoActions.setSubordinadosColaboradorFilter.type,
    subordinadosColaboradorSaga
  );
  //
  yield takeEvery(equipoActions.getEquipoNineboxData.type, equipoNineboxSaga);
  //
  yield takeEvery(equipoActions.getEquipoInformeEvaluaciones.type, equipoInformeEvaluacionesSaga);
}
