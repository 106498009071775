import React, { useEffect, useRef, useState } from "react";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import {
  DashCircle,
  PeopleFill,
  X,
  Download,
} from "react-bootstrap-icons";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import BarChart from "src/modules/common/components/BarChart";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import { evaluacionActions } from "../handlers/redux";
import { formatDate } from "src/modules/common/helpers/helpers";
import FormularioCalificaciones from "../components/FormularioCalificaciones";
import PersonPhoto from "src/modules/common/components/PersonPhoto";
import { getPuntuacionesCustom } from "../helpers/helpers";
import axios from "axios";
import { getToken } from "src/modules/common/handler/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { FormularioCalificacionesPromedio } from "../components/FormularioCalificacionesPromedio";
import NineBox from "../components/Ninebox";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";

const categorias = [
  "Promedio General", // 0
  "Jefe Directo", // 1
  "Pares", // 2
  "Subalternos", // 3
  "Autoevaluación", // 4
];

const barChartWidth = 600;

const getBarColor = (idx) => {
  const colors = [
    "#F7A25D", //0 7 14
    "#8085E9", //1 8 15
    "#F05C81", //2 9 16
    "#E5D254", //3 10
    "#DF3A33", //4 11
    "#69E455", //5 12
    "#373136", //6 13
  ];
  const safeIndex = idx % colors.length;
  return colors[safeIndex];
};

const getRelacionStyle = (relacion) => {
  const styles = {
    "Jefe directo": "bg-red-500 text-white p-1 rounded-md",
    Autoevaluación: "bg-slate-400 text-black p-1 rounded-md",
    Par: "bg-yellow-400 text-white p-1 rounded-md",
    Subalterno: "bg-sky-500 text-white p-1 rounded-md",
  };
  const relacionStyle = styles[relacion] || "bg-slate-400 p-1 rounded-md";
  return relacionStyle;
};

function ColaboradorInformePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion, idColaborador } = params;
  const initDiv = useRef(null);

  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacion_loading = useSelector(
    (state) => state.evaluacion.evaluacion_loading
  )
  const informe_colaborador = useSelector(
    (state) => state.evaluacion.informe_colaborador
  );
  const informe_colaborador_loading = useSelector(
    (state) => state.evaluacion.informe_colaborador_loading
  );
  const nineboxData = useSelector(
    (state) => state.evaluacion.ninebox_data_individual
  );
  const nineboxDataLoading = useSelector(
    (state) => state.evaluacion.ninebox_data_individual_loading
  );

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [activeCategoria, setActiveCategoria] = useState(0);
  const [activeCategoriaComentario, setActiveCategoriaComentario] = useState(0);
  const [activeRelacionFormulario, setActiveRelacionFormulario] = useState(0);

  const { tipo_evaluacion } = evaluacion;
  const {
    evaluado = {},
    evaluadores = [],
    promedios_relacion = {},
    configuraciones = [],
    resumen_generales = {},
    resumen_kpis = [],
    resumen_objetivos_cargo = [],
    formulario_jefe_directo = {},
    formulario_pares = {},
    formulario_subalternos = {},
    formulario_autoevaluacion = {},
    comentarios_evaluadores = {},
  } = informe_colaborador;
  const { jefe_directo = {} } = evaluado;

  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    const informeParams = { id: idEvaluacion, colaboradorId: idColaborador };
    dispatch(evaluacionActions.getInformeColaborador(informeParams));
    dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    dispatch(
      evaluacionActions.getNineboxDataIndividual({
        evaluacion_id: idEvaluacion,
        colaborador_id: idColaborador,
      })
    );
    if (initDiv.current) {
      initDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  useEffect(() => {
    if (tipo_evaluacion === 2) {
      setActiveCategoria(1); // Cuando es evaluación de potencial, solo debe mostrar datos de Jefe directo
    }
  }, [tipo_evaluacion])

  const datosEvaluado = [
    { label: "Nombre", value: `${evaluado.nombre}` },
    { label: "Apellido", value: `${evaluado.apellido}` },
    {
      label: "Sucursal",
      value: `${evaluado.sucursal?.nombre || "Sin asignar"}`,
    },
    { label: "Área", value: `${evaluado.area?.nombre || "Sin asignar"}` },
    {
      label: "Departamento",
      value: `${evaluado.departamento?.nombre || "Sin asignar"}`,
    },
    { label: "Cargo", value: `${evaluado.cargo?.nombre || "Sin asignar"}` },
  ];

  const getResumenTipoEvaluacionData = () => {
    const {
      jefe_directo = 0,
      par = 0,
      subalterno = 0,
      autoevaluacion = 0,
    } = promedios_relacion;
    let data = [
      { x: 1, y: jefe_directo, fill: "rgb(14 165 233)", name: "Jefe directo" },
      { x: 2, y: par, fill: "rgb(14 165 233)", name: "Pares" },
      { x: 3, y: subalterno, fill: "rgb(14 165 233)", name: "Subalternos" },
      {
        x: 4,
        y: autoevaluacion,
        fill: "rgb(14 165 233)",
        name: "Autoevaluación",
      },
    ];
    return data;
  };

  const resumenCompetenciasData = (relacion, subProp = "generales") => {
    let items = resumen_generales[relacion] || [];
    let data = [];
    //
    if (relacion === "jefe_directo") {
      items = items[subProp] || [];
    }
    //
    if (items.length < 1) return [];
    data = items.map((item, idx) => ({
      x: idx + 1,
      y: item.calificacion,
      name: item.competencia,
      fill: getBarColor(idx),
    }));
    //
    return data;
  };

  const getKpisData = () => {
    let data = [];
    if (resumen_kpis.length > 0)
      data = resumen_kpis?.map((kpi, idx) => ({
        x: idx + 1,
        y: kpi.calificacion,
        name: kpi.kpi.nombre,
        fill: getBarColor(idx),
      }));
    return data;
  };

  const getObjetivosCargoData = () => {
    let data = [];
    if (resumen_objetivos_cargo.length > 0)
      data = resumen_objetivos_cargo.map((objetivo_cargo, idx) => ({
        x: idx + 1,
        y: objetivo_cargo.calificacion,
        name: objetivo_cargo.objetivo_cargo.nombre,
        fill: getBarColor(idx),
      }));
    return data;
  };

  const getInputComentarios = (formulario) => {
    const {
      tiene_calibracion_superior,
      tiene_devolucion,
      tiene_comentarios_evaluado,
    } = evaluacion;
    let inputComentarios = {
      comentarios_evaluador: { show: true, editable: false },
      comentarios_calibrador: {
        show: tiene_calibracion_superior,
        editable: false,
      },
      plan_accion: { show: tiene_devolucion, editable: false },
      comentarios_evaluado: {
        show: tiene_comentarios_evaluado,
        editable: false,
      },
    };
    const { comentarios_calibrador } = formulario;
    //
    if (!comentarios_calibrador) {
      inputComentarios.comentarios_calibrador.show = false;
    }
    //
    const { relacion } = formulario;
    if (relacion === "Autoevaluación") {
      inputComentarios.plan_accion.show = false;
      inputComentarios.comentarios_evaluado.show = false;
    }
    return inputComentarios;
  };

  const ConfiguracionDetalle = ({ configuracion }) => {
    const {
      peso_generales,
      peso_areas,
      peso_cargos,
      peso_kpis,
      peso_objetivos_cargo,
    } = configuracion;
    //
    return (
      <div className="flex justify-between gap-3 p-2">
        <div className="font-semibold">{configuracion.nombre}</div>
        <div className="flex gap-2 items-center justify-start">
          {!!peso_generales && (
            <div className="p-1 rounded-md bg-black text-white text-sm">
              Generales
            </div>
          )}
          {!!peso_areas && (
            <div className="p-1 rounded-md bg-black text-white text-sm">
              Áreas
            </div>
          )}
          {!!peso_cargos && (
            <div className="p-1 rounded-md bg-black text-white text-sm">
              Cargos
            </div>
          )}
          {!!peso_kpis && (
            <div className="p-1 rounded-md bg-black text-white text-sm">
              KPIs
            </div>
          )}
          {!!peso_objetivos_cargo && (
            <div className="p-1 rounded-md bg-black text-white text-sm">
              Objetivos por cargo
            </div>
          )}
        </div>
      </div>
    );
  };

  const downloadPdfPorRelacion = (relacion) => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
    setDownloadLoading(true);
    // const currentStep = "evaluacion";
    axios({
      url:
        URL_BASE +
        `/evaluacion/${idEvaluacion}/evaluado/${evaluado.id}/relacion/${relacion}/exportar`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `formularios-${relacion}-${evaluacion.id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        ErrorToast({
          message: `Error al descargar el archivo PDF. Verifique que tenga evaluaciones con la relación de tipo ${relacion}`,
        });
        setDownloadLoading(false);
      });
  };

  const RenderFormularioConPromedios = ({ formulario, relacionId }) => {
    return (
      <div>
        {Object.keys(formulario).length > 0 && (
          <>
            <div className="border-b my-6 pb-3">
              <span className="font-bold">Formulario de evaluación:</span>{" "}
              <span className="font-bold uppercase text-sky-500">
                {relaciones[relacionId]}
              </span>
            </div>
            <FormularioCalificacionesPromedio formulario={formulario} />
          </>
        )}
        {Object.keys(formulario).length < 1 && (
          <div className="flex justify-center border rounded-md p-2 my-4">
            No existe formulario de {relaciones[relacionId]}.
          </div>
        )}
      </div>
    );
  };

  const RenderFormulario = ({ formulario, relacionId }) => {
    return (
      <div>
        {Object.keys(formulario).length > 0 && (
          <>
            <div className="border-b my-6 pb-3 mb-6">
              <span className="font-bold">Formulario de evaluación:</span>{" "}
              <span className="font-bold uppercase text-sky-500">
                {relaciones[relacionId]}
              </span>
            </div>
            <FormularioCalificaciones
              formulario={formulario}
              etapa={formulario.etapa}
              inputComentarios={getInputComentarios(formulario)}
              disabledFields={["indicadores"]}
              verAnotaciones={true}
              esEditable={false}
              showSubmitButton={false}
              puntuacionesCustom={getPuntuacionesCustom(evaluacion)}
              mostrarPesosIndicadores={
                evaluacion?.visualizar_pesos_indicadores_observacion
              }
              habilitarOpcionNoAplica={tipo_evaluacion !== 2}
            ></FormularioCalificaciones>
          </>
        )}
        {Object.keys(formulario).length < 1 && (
          <div className="flex justify-center border rounded-md p-2 my-4">
            No existe formulario de {relaciones[relacionId]}.
          </div>
        )}
      </div>
    );
  };

  const RenderFormuarioPorRelacion = ({ relacionId }) => {
    switch (relacionId) {
      case 0: // Jefe directo tiene índice 0 en el array relaciones
        return (
          <RenderFormulario
            formulario={formulario_jefe_directo}
            relacionId={relacionId}
          />
        );
      case 1:
        return (
          <RenderFormularioConPromedios
            formulario={formulario_pares}
            relacionId={relacionId}
          />
        );
      case 2:
        return (
          <RenderFormularioConPromedios
            formulario={formulario_subalternos}
            relacionId={relacionId}
          />
        );
      case 3:
        return (
          <RenderFormulario
            formulario={formulario_autoevaluacion}
            relacionId={relacionId}
          />
        );
      default:
        return (
          <div>No existe formulario para la relacion con ID: {relacionId}</div>
        );
    }
  };

  const onChangeCategoria = (catId) => {
    setActiveCategoria(catId);
  };
  //
  // Los valores de relaciones son las claves que se recibe de comentarios_evaluadores
  // Los valores de relaciones y las claves de comentarios_evaluadores deben coincidir
  const relaciones = [
    "Jefe directo", // 0
    "Pares", // 1
    "Subalternos", // 2
    "Autoevaluación", // 3
  ];
  const onChangeCategoriaComentario = (catId) => {
    setActiveCategoriaComentario(catId);
  };
  const onChangeRelacionFormularios = (relacionId) => {
    setActiveRelacionFormulario(relacionId);
  };

  const renderResumenCompetenciasKpisObjetivos = () => {
    return (
      <div className="w-full">
        {/* Para competencias de potencial no hace falta mostrar las pestañas, porque solo el jefe directo puede evaluar, entonces se muestra solo el de él */}
        {tipo_evaluacion === 1 && (
          <div className="flex flex-wrap gap-2 custom-scrollbar">
            {categorias.map((cat, idx) => {
              return (
                <div>
                  <button
                    key={`cat-btn-${idx}`}
                    onClick={() => onChangeCategoria(idx)}
                    className={`bg-slate-200 p-1 rounded-md text-sm whitespace-nowrap`}
                    style={{ border: `1px solid ${idx === activeCategoria ? "#0ea5e9" : "#e2e8f0"}` }}
                  >
                    {cat}
                  </button>
                </div>
              );
            })}
          </div>
        )}
        <div className="w-full h-[300px] border rounded-md custom-scrollbar mt-2">
          {/* Competencias */}
          <div className="p-4">
            <div className="font-bold mb-4">Competencias</div>
            <div className="flex flex-col gap-2">
              {/* PROMEDIOS GENERALES */}
              {resumenCompetenciasData("generales").length > 0 &&
                activeCategoria === 0 && (
                  <div>
                    <label className="text-xs bg-slate-200 rounded-md py-1 px-2">
                      GENERALES
                    </label>
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData("generales")}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {resumenCompetenciasData("generales").length === 0 &&
                activeCategoria === 0 && (
                  <div className="p-2 mx-4 mt-4">
                    <div className="text-center">
                      No existen registros
                    </div>
                  </div>
                )}
              {/* JEFE DIRECTO */}
              <div>
                {/* Competencias Generales */}
                {resumenCompetenciasData("jefe_directo").length > 0 &&
                  activeCategoria === 1 && (
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="flex items-center text-sm">
                          <div className="rounded-md bg-slate-200 p-1">
                            Generales
                          </div>
                        </div>
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData("jefe_directo")}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  )}
                {/* Areas */}
                {resumenCompetenciasData("jefe_directo", "areas").length >
                  0 &&
                  activeCategoria === 1 && (
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="flex items-center text-sm">
                          <div className="rounded-md bg-slate-200 p-1">
                            Áreas
                          </div>
                        </div>
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData(
                              "jefe_directo",
                              "areas"
                            )}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  )}
                {/* Cargos */}
                {resumenCompetenciasData("jefe_directo", "cargos")
                  .length > 0 &&
                  activeCategoria === 1 && (
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="flex items-center text-sm">
                          <div className="rounded-md bg-slate-200 p-1">
                            Cargos
                          </div>
                        </div>
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData(
                              "jefe_directo",
                              "cargos"
                            )}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  )}
                {/* Competencias de Potencial */}
                {resumenCompetenciasData("jefe_directo", "potenciales").length > 0 &&
                  activeCategoria === 1 && (
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="w-full">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData("jefe_directo", "potenciales")}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  )}
                {resumenCompetenciasData("jefe_directo").length === 0 &&
                  resumenCompetenciasData("jefe_directo", "areas").length === 0 &&
                  resumenCompetenciasData("jefe_directo", "cargos").length === 0 &&
                  resumenCompetenciasData("jefe_directo", "potenciales").length === 0 &&
                  activeCategoria === 1 && (
                    <div className="p-2 mx-4 mt-4">
                      <div className="text-center">
                        No existen registros
                      </div>
                    </div>
                  )}
              </div>
              {/* PARES */}
              {resumenCompetenciasData("par").length > 0 &&
                activeCategoria === 2 && (
                  <div>
                    <label className="text-xs bg-slate-200 rounded-md py-1 px-2">
                      PARES
                    </label>
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData("par")}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {resumenCompetenciasData("par").length === 0 &&
                activeCategoria === 2 && (
                  <div className="p-2 mx-4 mt-4">
                    <div className="text-center">
                      No existen registros
                    </div>
                  </div>
                )}
              {/* SUBALTERNOS */}
              {resumenCompetenciasData("subalterno").length > 0 &&
                activeCategoria === 3 && (
                  <div>
                    <label className="text-xs bg-slate-200 rounded-md py-1 px-2">
                      SUBALTERNOS
                    </label>
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData("subalterno")}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {resumenCompetenciasData("subalterno").length === 0 &&
                activeCategoria === 3 && (
                  <div className="p-2 mx-4 mt-4">
                    <div className="text-center">
                      No existen registros
                    </div>
                  </div>
                )}
              {/* AUTOEVALUACION */}
              {resumenCompetenciasData("autoevaluacion").length > 0 &&
                activeCategoria === 4 && (
                  <div>
                    <label className="text-xs bg-slate-200 rounded-md py-1 px-2">
                      AUTOEVALUACION
                    </label>
                    <div className="flex h-72">
                      <div className="flex justify-center w-full h-full">
                        <div className="w-full md:w-2/3">
                          <BarChart
                            width={barChartWidth}
                            domainPadding={{ x: 40, y: [0, 0] }}
                            data={resumenCompetenciasData(
                              "autoevaluacion"
                            )}
                            usingXLabels={true}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {resumenCompetenciasData("autoevaluacion").length === 0 &&
                activeCategoria === 4 && (
                  <div className="p-2 mx-4 mt-4">
                    <div className="text-center">
                      No existen registros
                    </div>
                  </div>
                )}
            </div>
          </div>
          {/* KPIs */}
          {getKpisData().length > 0 && activeCategoria === 1 && (
            <div className="p-4 mt-6">
              <div className="font-bold mb-4">KPIs</div>
              {/* JEFE DIRECTO */}
              <div>
                <div className="flex h-72">
                  <div className="flex justify-center w-full h-full">
                    <div className="w-full">
                      <BarChart
                        width={barChartWidth}
                        domainPadding={{ x: 40, y: [0, 0] }}
                        data={getKpisData()}
                        usingXLabels={true}
                      ></BarChart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Objetivos por cargo */}
          {getObjetivosCargoData().length > 0 &&
            activeCategoria === 1 && (
              <div className="p-4 mt-6">
                <div className="font-bold mb-4">Objetivos por cargo</div>
                {/* JEFE DIRECTO */}
                <div>
                  <div className="flex h-72">
                    <div className="flex justify-center w-full h-full">
                      <div className="w-full">
                        <BarChart
                          width={barChartWidth}
                          domainPadding={{ x: 40, y: [0, 0] }}
                          data={getObjetivosCargoData()}
                          usingXLabels={true}
                        ></BarChart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    )
  }
  //
  return (
    <div ref={initDiv} className="px-2 md:px-8">
      {/* HEADER */}
      <div className="flex items-center bg-gray-100 py-2.5 px-7 -mx-8 border-b">
        <div
          onClick={() => navigate(-1)}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-xl">Informe de evaluación</div>
        <div className="flex items-center button-group"></div>
      </div>
      {/* BODY */}
      {!informe_colaborador_loading &&
        Object.keys(informe_colaborador).length > 0 && (
          <div className="px-8">
            {/* INFO COLABORADOR Y EVALUADORES */}
            <div className="grid grid-cols-1 md:grid-cols-12 mt-8 gap-1 md:!gap-4">
              {/* Foto y Jefe */}
              <div className="cols-span-1 md:col-span-2 flex flex-col gap-20">
                <div className="w-full h-36 shrink-0 flex justify-center">
                  <PersonPhoto
                    size={36}
                    image={evaluado.foto_empleado}
                  ></PersonPhoto>
                </div>
                <div className="pl-6">
                  <label className="font-bold" htmlFor="">
                    Jefe directo:
                  </label>
                  <div className="flex flex-col md:flex-row gap-2">
                    <div className="w-12 h-12 shrink-0">
                      <PersonPhoto
                        size={12}
                        image={jefe_directo?.foto_empleado}
                      ></PersonPhoto>
                    </div>
                    <div className="text-sm">
                      <div>
                        {!!jefe_directo
                          ? `${jefe_directo?.nombre} ${jefe_directo?.apellido}`
                          : "Sin asignar"}
                      </div>
                      <div className="text-slate-500">
                        {!!jefe_directo?.cargo
                          ? `${jefe_directo?.cargo?.nombre}`
                          : "Sin asignar"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Datos evaluado y Formularios */}
              <div className="cols-span-1 md:col-span-5 flex flex-col gap-3">
                <div className="border rounded-md p-4 flex-1">
                  <div className="font-bold">Datos del evaluado</div>
                  <div className="grid grid-cols-2 mt-2">
                    {datosEvaluado.map((dato, idx) => (
                      <div key={`dato-${idx}`} className="col-span-1">
                        <label>{dato.label}:</label>
                        <div className="text-slate-500">{dato.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border rounded-md p-4 flex-1 relative">
                  <div className="font-bold">Formularios de evaluación</div>
                  {configuraciones.length > 0 && (
                    <>
                      <div className="group flex gap-2 text-sm">
                        <div className="hidden group-hover:block group-hover:absolute p-2 -top-10 -left-10 bg-white border-2 rounded-md flex-col gap-2">
                          {configuraciones.map((configuracion, idx) => (
                            <ConfiguracionDetalle
                              key={`cd-${idx}`}
                              configuracion={configuracion}
                            ></ConfiguracionDetalle>
                          ))}
                        </div>
                        {configuraciones.map((configuracion, idx) => (
                          <span
                            key={`config-${idx}`}
                            className="bg-black text-white px-1 rounded-md"
                          >
                            {configuracion.nombre}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                  {configuraciones.length < 1 && (
                    <div className="pt-1">
                      No se registran formularios de evaluación.
                    </div>
                  )}
                </div>
              </div>
              {/* Evaluadores */}
              <div className="cols-span-1 md:col-span-5 flex">
                <div className="border rounded-md p-4 flex-1">
                  <div className="flex items-center gap-2">
                    <div className="bg-blue-500 p-2 rounded-md">
                      <PeopleFill color={"white"} size={21}></PeopleFill>
                    </div>
                    <div className="font-bold">Evaluadores</div>
                  </div>
                  {/*  */}
                  <div className="flex flex-col gap-2 mt-4">
                    {evaluadores.map((evaluadorInfo, idx) => {
                      let profilePicture = null;
                      if (evaluadorInfo.relacion === "Jefe directo") {
                        profilePicture = jefe_directo?.foto_empleado;
                      } else if (evaluadorInfo.relacion === "Autoevaluación") {
                        profilePicture = evaluado.foto_empleado;
                      }
                      return (
                        <div key={"ei-" + idx} className="flex items-center">
                          <ProfileInfoCard
                            image={profilePicture}
                            name={evaluadorInfo.nombre}
                          >
                            {(evaluadorInfo.relacion === "Jefe directo" ||
                              evaluadorInfo.relacion === "Autoevaluación") && (
                                <div className="text-sm">
                                  Fecha: {formatDate(evaluadorInfo.descripcion)}
                                </div>
                              )}
                            {(evaluadorInfo.relacion === "Par" ||
                              evaluadorInfo.relacion === "Subalterno") && (
                                <div className="text-sm">
                                  {evaluadorInfo.descripcion}
                                </div>
                              )}
                          </ProfileInfoCard>
                          <div className="flex gap-2 items-center justify-between">
                            <div
                              className={getRelacionStyle(
                                evaluadorInfo.relacion
                              )}
                            >
                              {evaluadorInfo.relacion}
                            </div>
                            {!downloadLoading &&
                              evaluadorInfo.relacion !== "Par" &&
                              evaluadorInfo.relacion !== "Subalterno" && (
                                <Download
                                  className="cursor-pointer"
                                  onClick={() =>
                                    downloadPdfPorRelacion(
                                      evaluadorInfo.relacion
                                    )
                                  }
                                />
                              )}
                            {downloadLoading &&
                              evaluadorInfo.relacion !== "Par" &&
                              evaluadorInfo.relacion !== "Subalterno" && (
                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-sky-500"></div>
                              )}
                            {(evaluadorInfo.relacion === "Par" ||
                              evaluadorInfo.relacion === "Subalterno") && (
                                <div className="h-5 w-5"></div>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* NINE BOX */}
            {tipo_evaluacion === 2 && (
              <>
                <div className="font-bold text-lg mt-6">Nine Box</div>
                {!nineboxDataLoading && (
                  <>
                    <NineBox nineboxData={nineboxData} extraClassName="my-3" />
                  </>
                )}
                {nineboxDataLoading && (
                  <div className="flex justify-center items-center p-2">
                    <LoadingSpinButton message="Cargando Nine Box..." />
                  </div>
                )}
              </>
            )}
            {/* PUNTAJES POR TIPO DE EVALUADORES */}
            {tipo_evaluacion === 1 && (
              <>
                <div className="font-bold text-lg my-6">
                  Puntajes por tipo de evaluadores
                </div>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                  {/* Gráfico */}
                  <div className="col-span-1 md:col-span-9">
                    <div className="border rounded-md p-4 h-96">
                      <div className="font-bold">
                        Resumen por tipo de evaluación
                      </div>
                      <div className="flex justify-center h-full">
                        <div className="w-full md:w-2/3">
                          <BarChart
                            domainPadding={{ x: 35, y: [0, 0] }}
                            data={getResumenTipoEvaluacionData()}
                            usingXLabels={true}
                            tickLabelsFontSize={14}
                          ></BarChart>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Promedio Desempeño */}
                  <div className="col-span-1 md:col-span-3 flex">
                    <div className="border rounded-md p-4 flex-1 flex flex-col">
                      <div className="font-bold">Promedio desempeño</div>
                      <div className="flex flex-col items-center justify-between flex-1 gap-2 mt-2">
                        <div className="mt-4">
                          <PercentageCircular
                            width={180}
                            height={180}
                            strokeWidth={9}
                            strokeColor={"blue"}
                            value={promedios_relacion.general || 0}
                            content={
                              <>
                                <div className="font-bold text-2xl">
                                  {promedios_relacion.general || 0}%
                                </div>
                                <div className="text-sm">{`${promedios_relacion.general_clasificacion || ""
                                  }`}</div>
                              </>
                            }
                          ></PercentageCircular>
                        </div>
                        <div className="text-slate-400 text-sm text-center">
                          Promedio entre jefes, pares, subalternos y
                          autoevaluación.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* RESUMEN POR COMPETENCIAS, KPIS Y OBJETIVOS POR CARGO*/}
            <div className="font-bold text-lg my-6">
              Resumen por Competencias, KPIs y Objetivos por cargo
            </div>
            {tipo_evaluacion === 1 && (
              <>{renderResumenCompetenciasKpisObjetivos()}</>
            )}
            {tipo_evaluacion === 2 && (
              <div className="flex justify-between gap-4">
                {renderResumenCompetenciasKpisObjetivos()}
                {/* Cuando es evaluación de potencial, a lado del resumen de competencias se muestra el puntaje obtenido (promedio) */}
                <div className="col-span-1 md:col-span-3 flex">
                  <div className="border rounded-md p-4 flex-1 flex flex-col">
                    <div className="font-bold">Puntaje obtenido</div>
                    <div className="flex flex-col items-center justify-between flex-1 gap-2 mt-2">
                      <div className="mt-4">
                        <PercentageCircular
                          width={180}
                          height={180}
                          strokeWidth={9}
                          strokeColor={"blue"}
                          value={promedios_relacion.general || 0}
                          content={
                            <>
                              <div className="font-bold text-2xl">
                                {promedios_relacion.general || 0}%
                              </div>
                              <div className="text-sm">{`${promedios_relacion.general_clasificacion || ""
                                }`}</div>
                            </>
                          }
                        ></PercentageCircular>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Para competencias de potencial no hace falta mostrar los comentarios, porque solo el jefe directo puede evaluar y su comentario se puede ver más abajo al mostrar el formulario */}
            {tipo_evaluacion === 1 && !evaluacion_loading && (
              <>
                <div className="font-bold text-lg my-6">
                  Comentarios según tipo de evaluador
                </div>
                <div className="flex flex-wrap gap-2 custom-scrollbar">
                  {relaciones.map((cat, idx) => {
                    return (
                      <div>
                        <button
                          key={`cat-btn-${idx}`}
                          onClick={() => onChangeCategoriaComentario(idx)}
                          className={`bg-slate-200 p-1 rounded-md text-sm whitespace-nowrap`}
                          style={{ border: `1px solid ${idx === activeCategoriaComentario ? "#0ea5e9" : "#e2e8f0"}` }}
                        >
                          {cat}
                        </button>
                      </div>
                    );
                  })}
                </div>
                {!!comentarios_evaluadores &&
                  Object.keys(comentarios_evaluadores).length > 0 ? (
                  <div className="flex flex-col max-h-[400px] border rounded-md overflow-y-auto custom-scrollbar mt-2 pb-4">
                    {comentarios_evaluadores[
                      relaciones[activeCategoriaComentario]
                    ] &&
                      comentarios_evaluadores[relaciones[activeCategoriaComentario]]
                        .length > 0 ? (
                      <div className="flex flex-col max-h-[400px] overflow-y-auto custom-scrollbar mt-2 pb-4">
                        {comentarios_evaluadores[
                          relaciones[activeCategoriaComentario]
                        ].map((com, idx) => {
                          return (
                            <div
                              className="border rounded-md p-2 mx-4 mt-4 whitespace-pre-wrap"
                              key={idx}
                            >
                              {com}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="p-2 mx-4 mt-4">
                        <div className="text-center">No hay comentarios</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="border rounded-md p-2 my-4">
                    <div className="text-center">No hay comentarios</div>
                  </div>
                )}
              </>
            )}
            {/* FORMULARIOS SEGÚN TIPO DE EVALUADOR */}
            <>
              {/* Para competencias de potencial no hace falta mostrar las pestañas por tipos de evaluador, porque solo el jefe directo puede evaluar, entonces se muestra solo el de él */}
              {tipo_evaluacion === 1 && (
                <div>
                  <div className="font-bold text-lg my-6">
                    Formularios según tipo de evaluador
                  </div>
                  <div className="flex flex-wrap gap-2 custom-scrollbar">
                    {relaciones.map((cat, idx) => {
                      return (
                        <div>
                          <button
                            key={`cat-btn-${idx}`}
                            onClick={() => onChangeRelacionFormularios(idx)}
                            className={`bg-slate-200 p-1 rounded-md text-sm whitespace-nowrap`}
                            style={{ border: `1px solid ${idx === activeRelacionFormulario ? "#0ea5e9" : "#e2e8f0"}` }}
                          >
                            {cat}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* @ts-ignore */}
              <RenderFormuarioPorRelacion
                relacionId={activeRelacionFormulario}
              />
            </>
          </div>
        )}
      {/* LOADING */}
      {!!informe_colaborador_loading && (
        <>
          <div className="animate-pulse">
            <div className="grid grid-cols-1 md:grid-cols-8 mt-8 gap-1 md:gap-8">
              <div className="bg-slate-300 rounded-md h-72 col-span-1"></div>
              <div className="bg-slate-300 rounded-md h-72 col-span-1 md:col-span-3"></div>
              <div className="bg-slate-300 rounded-md h-72 col-span-1 md:col-span-4"></div>
            </div>
            <div></div>
            <div></div>
          </div>
          {/*  */}
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-8">
            <div className="col-span-1 md:col-span-9 rounded-md bg-slate-300 h-80"></div>
            <div className="col-span-1 md:col-span-3 rounded-md bg-slate-300 h-80"></div>
          </div>
          {/*  */}
          <div className="bg-slate-300 rounded-md h-screen mt-6"></div>
        </>
      )}
      {Object.keys(informe_colaborador).length < 1 && (
        <div className="flex items-center justify-center border rounded-md h-screen md:h-72 mt-8 mx-12">
          <div className="flex flex-col items-center gap-4">
            <DashCircle color={"red"} size={48}></DashCircle>
            No existen datos para mostrar
          </div>
        </div>
      )}
    </div>
  );
}

export default ColaboradorInformePage;
