import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompetenciaItemFeedback } from "./CompetenciaItemFeedback";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { feedbackActions } from "../handlers/redux";
import TabButton from "src/modules/common/components/TabButton";

const categorias = [
  {
    clave: "general",
    nombre: "Generales",
  },
  {
    clave: "area",
    nombre: "Áreas",
  },
  {
    clave: "cargo",
    nombre: "Cargos",
  },
];

export function CompetenciasColaboradorSelect(props) {
  const { idFeedback, idColaborador, onSubmit = () => { } } = props;

  const dispatch = useDispatch();

  const competencias_generales = useSelector(
    (state) => state.feedback.competencias_generales
  );
  const competencias_generales_loading = useSelector(
    (state) => state.feedback.competencias_generales_loading
  );
  const competencias_areas = useSelector(
    (state) => state.feedback.competencias_areas
  );
  const competencias_areas_loading = useSelector(
    (state) => state.feedback.competencias_areas_loading
  );
  const competencias_cargos = useSelector(
    (state) => state.feedback.competencias_cargos
  );
  const competencias_cargos_loading = useSelector(
    (state) => state.feedback.competencias_cargos_loading
  );

  const [activeTab, setActiveTab] = useState(0);
  const [selectedCategoria, setSelectedCategoria] = useState(categorias[0]);
  const [currentCategoria, setCurrentCategoria] = useState(null);

  const [activeCompetencia, setActiveCompetencia] = useState(null);
  const [selectedCompetencia, setSelectedCompetencia] = useState(null);

  useEffect(() => {
    if (idFeedback) {
      dispatch(feedbackActions.getCompetenciasGenerales(idFeedback));
    }
    if (idFeedback && idColaborador) {
      dispatch(
        feedbackActions.getCompetenciasAreas({ idFeedback, idColaborador })
      );
      dispatch(
        feedbackActions.getCompetenciasCargos({ idFeedback, idColaborador })
      );
    }
  }, []);

  const onChangeTab = (idx) => {
    const clave = categorias[idx].clave;
    setActiveTab(idx);
    setSelectedCategoria(categorias[idx]);
    setCurrentCategoria(categorias[idx]);
    // Cargamos null para que no este seleccionado ninguno
    setActiveCompetencia(null);
    setSelectedCompetencia(null);
    // if (clave === "general") {
    //   setSelectedCompetencia(
    //     competencias_generales ? competencias_generales[0] : {}
    //   );
    // } else if (clave === "area") {
    //   setSelectedCompetencia(competencias_areas ? competencias_areas[0] : {});
    // } else if (clave === "cargo") {
    //   setSelectedCompetencia(competencias_cargos ? competencias_cargos[0] : {});
    // }
  };

  const onChangeCompetencia = (idx) => {
    console.log("onChangeCompetencia", idx);
    setActiveCompetencia(idx);
    if (activeTab === 0) {
      setSelectedCompetencia(competencias_generales[idx]);
    } else if (activeTab === 1) {
      setSelectedCompetencia(competencias_areas[idx]);
    } else if (activeTab === 2) {
      setSelectedCompetencia(competencias_cargos[idx]);
    } else {
      setSelectedCompetencia(null);
    }
  };

  const onAddToFeedback = (competencia) => {
    onSubmit("Competencia", competencia);
  };

  return (
    <div className="">
      <div>
        <div className="flex flex-wrap gap-2 text-sm">
          {categorias.map((categoria, cidx) => (
            <TabButton
              title={categoria.nombre}
              isActive={cidx === activeTab}
              action={() => onChangeTab(cidx)} />
          ))}
        </div>
        <div className="my-4">
          {selectedCategoria.clave === "general" && (
            <div className="gap-1 p-1">
              <div className="font-semibold">Competencias generales</div>
              <div className="text-sm">
                Puede seleccionar una competencia para conocer más detalles
              </div>
              <div className="flex flex-col gap-1 mt-2">
                <div className="flex flex-wrap gap-2 custom-scrollbar">
                  {competencias_generales &&
                    competencias_generales.map((competencia, cidx) => (
                      <div>
                        <TabButton
                          title={competencia.nombre.length > 50
                            ? competencia.nombre.substring(0, 50) + "..."
                            : competencia.nombre}
                          isActive={cidx === activeCompetencia}
                          action={() => onChangeCompetencia(cidx)} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {selectedCategoria.clave === "area" && (
            <div className="gap-1  p-1">
              <div className="font-semibold">Competencias por área</div>
              <div className="text-sm">
                Puede seleccionar una competencia para conocer más detalles
              </div>
              <div className="flex flex-col gap-1 mt-2">
                <div className="flex flex-wrap gap-2 custom-scrollbar">
                  {competencias_areas &&
                    competencias_areas.map((competencia, cidx) => (
                      <div>
                        <TabButton
                          title={competencia.nombre.length > 50
                            ? competencia.nombre.substring(0, 50) + "..."
                            : competencia.nombre}
                          isActive={cidx === activeCompetencia}
                          action={() => onChangeCompetencia(cidx)} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {selectedCategoria.clave === "cargo" && (
            <div className="gap-1 p-1">
              <div className="font-semibold">Competencias por cargo</div>
              <div className="text-sm">
                Puede seleccionar una competencia para conocer más detalles
              </div>
              <div className="flex flex-col gap-1 mt-2">
                <div className="flex flex-wrap gap-2 custom-scrollbar">
                  {competencias_cargos &&
                    competencias_cargos.map((competencia, cidx) => (
                      <div>
                        <button
                          key={`cat-btn-${cidx}`}
                          onClick={() => onChangeCompetencia(cidx)}
                          className={`bg-slate-200 p-1 rounded-full border text-sm ${cidx === activeCompetencia
                            ? "border-sky-500"
                            : "border-slate-200"
                            } whitespace-nowrap`}
                        >
                          {competencia.nombre.length > 50
                            ? competencia.nombre.substring(0, 50) + "..."
                            : competencia.nombre}
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {!competencias_generales_loading &&
            !competencias_areas_loading &&
            !competencias_cargos_loading &&
            selectedCompetencia && (
              <CompetenciaItemFeedback
                key={selectedCompetencia ? selectedCompetencia.id : "0"}
                competencia={selectedCompetencia}
                idx={activeCompetencia}
                onSubmit={onAddToFeedback}
              />
            )}
          {(competencias_generales_loading ||
            competencias_areas_loading ||
            competencias_cargos_loading) &&
            selectedCompetencia && (
              <div className="flex justify-center p-2">
                <LoadingSpinButton
                  message={"Cargando, por favor espere"}
                ></LoadingSpinButton>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
